<div class="row" style="border-bottom: 2px solid #ccc; margin-bottom: 10px">
 
    <div
      class="col-sm-11 movable"
      cdkDrag
      cdkDragHandle
    >
      <h3 class="head">
       Breaks
      </h3>
    </div>

  <div class="col-sm-1">
    <button
      type="button"
      class="btn btn-dark btn-circle btn-xl"
      mat-dialog-close="cancel"
      data-dismiss="modal"
      aria-label="Close"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
</div>
<!-- <div class="row">
  <div class="col-md-8">
    <span class="edit_icon">
      <img
      
        class="img-tool"
        src="./assets/images/edit.svg"
        alt="edit button icon"
        title="Edit"
      />
     
    </span>
  </div>
</div> -->

<div class="col-md-12">
  <div class="row">
    <div class="col-md-8 ">
      <!-- <p style="font-weight: bold; margin-top: 17px">
        Required Arrival Schdules
      </p> -->
    </div>
    <div class="col-md-4">
      <!-- <label class="resetlabel1"> -->
      <!-- <p (click)="addRow()">Add Arrival Date</p> -->
      <button class="cancel_btn breakShift_btn" (click)="addRow()">
        ADD BREAKS
      </button>
      <!-- </label> -->
    </div>
  </div>
  <br>
  <ag-grid-angular
    #osPartsGrid
    style="
      width: 95%;
      margin-top: 7px;
      height: 180px !important;
      top: 3%;
      margin-bottom: 3%;
    "
    class="ag-theme-balham table-outer"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [rowSelection]="rowSelection"
    [gridOptions]="gridOptions"
    [context]="context"
    [editType]="editType"
    [suppressClickEdit]="suppressClickEdit"
    (cellClicked)="onCellClicked($event)"
    [suppressClickEdit]="true"
    [frameworkComponents]="frameworkComponents"
  >
  </ag-grid-angular>
  <div class="mt-3" >
    <!-- <div class="mt-3" *ngIf="isExpanded[i]"> -->
    <button class="review_btn" >Save</button>
    <!-- <button class="review_btn" (click)="saveOrder()">Save</button> -->
    <!-- <button class="cancel_btn1" (click)="addPart()">Add Part</button> -->
  </div>
</div>

<div *ngIf="isEditMode" class="saveCancel">
  <button
    matRipple
    [matRippleCentered]="true"
    [matRippleUnbounded]="false"
    class="review_btn"
    (click)="reviewData()"
  >
    Review Changes
  </button>
  <button (click)="cancelEdit()" class="cancel_btn">Cancel</button>
</div>


