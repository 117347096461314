import { Component } from '@angular/core';

@Component({
  selector: 'app-actual-arrival-editable',
  template: `
  <input
    #input
    type="number"
    [(ngModel)]="value"
    class="grid-cell-edit-layout"
    (change)="textUpdated()"
    (keydown)="onKeyPress($event)"
    maxlength="5"

  />
`,
styles: [
  `
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    .grid-cell-edit-layout {
      /* width: 123px; */
      height: 26px;
      border: 1px solid #d1d1d1;
    }
    .invalid-text {
      border: 2px solid red !important;
    }
  `,
],

})
export class EditNoOfLotsEditableComponent {
  public params: any;
  showInput = true;
  value: any;
  isEmpty = true;

  constructor() {}

  agInit(params: any): void {
    console.log('edit no of pieces');
    params.data.noOfLots = this;
    if (params.column === 'number_of_lots') {
      this.value = params.data.number_of_lots;
      // if (this.value > 0) {
      //   this.isEmpty = false;
      // } else {
      //   this.isEmpty = true;
      // }
    }

    this.params = params;
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  public textUpdated() {
    if (this.params.column === 'number_of_lots') {
      if (this.value !== this.params.data.number_of_lots) {
        this.params.data.number_of_lots = this.value;
      }
    }
  }

  onKeyPress(event: KeyboardEvent) {
    const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];
    const isNumber = /^[0-9]$/.test(event.key);

    // Allow number keys, allowed keys, and prevent default for others
    if (!isNumber && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }
}
