import { Component } from '@angular/core';
import * as moment from 'moment';
import * as mm from 'moment-timezone';
@Component({
  selector: 'app-actual-arrival-editable',
  template: `
    <input #input [(ngModel)]="value" type="date" class="grid-cell-edit-layout " (change)="textUpdated()" [min]="minDate"/>
  `,
  styles: [
    `
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      .grid-cell-edit-layout {
        width: 123px;
        height: 26px;
        border: 1px solid #d1d1d1;
      }
      .invalid-text {
        border: 2px solid red !important;
      }
    `,
  ],
})
export class EditActualArrivalEditableComponent {
  public params: any;
  showInput = true;
  value: any;
  isEmpty = true;
  easternCurrentDate: string;
  minDate: string;

  constructor() {}

  agInit(params: any): void {
   

    this.easternCurrentDate = String(mm().tz('US/Michigan').format('YYYY-MM-DD')).substring(0, 10);

    this.minDate = new Date().toISOString().split('T')[0];

    params.data.arrivalDate = this;

    if (params.column === 'arrival_date') {
      this.value = params.data.arrival_date;
      this.value = moment(this.value).format('YYYY-MM-DD');
    }

    this.params = params;
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  public textUpdated() {
    console.log(this.params.data, 'ui');
    if (this.params.column === 'arrival_date') {
      if (this.value !== this.params.data.arrival_date) {
        this.params.data.arrival_date = this.value;
        this.params.data.isUpdated = true;
      }
    } else {
      this.params.data.finalquantity = this.value;
    }
  }

  onKeyPress(event) {
    if (event.target.value.length <= 4) {
      return true;
    } else {
      return false;
    }
  }
}
