<div style="display : inline-block;margin-top: -3px;" >
    <a  id="edit">
        <img   class="detailiconhome" (click)="editData($event)"  style = "margin-right: 2px;" src="./assets/images/Edit_icon.svg" title="edit" alt="Edit">
    </a>
    <span></span>
    <a  id="delete">
        <img   class="detailiconhome" (click)="deleteChanged()" src="assets/images/icon-delete.svg" title="delete" alt="Delete">
    </a>
    <span></span>
    
</div>