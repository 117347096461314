import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-air-order-cell-renderer',
  templateUrl: './air-order-cell-renderer.component.html',
})
export class AirOrderCellRendererComponent implements ICellRendererAngularComp {
  params: any;
  public isGroupRow: boolean;

  agInit(params: any): void {
    this.params = params;
  
    
    // Ensure params.node.group exists or fallback to false
    this.isGroupRow = params.node?.group ?? false;
  
    
  }

  refresh(params: any): boolean {
    this.isGroupRow = params.node.group??false;
    
    return true;
    
  }

  onClick(): void {
    console.log(`Air Order No clicked: ${this.params.value}`);
    this.params.context.componentParent.handleAirOrderClick(this.params.value);
  }
}
