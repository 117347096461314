import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { HeaderCheckboxComponent } from '../core/grid/header-checkbox/header-checkbox.component';
import { GridRefreshComponent } from '../core/grid/grid-refresh/grid-refresh.component';
import { RowCheckboxComponent } from '../core/grid/row-checkbox/row-checkbox.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomTooltipComponent } from '../core/custom-tooltip/custom-tooltip.component';
import { GriddataService } from '../services/griddata.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ExcelService } from '../services/excel.service';
import * as moment from 'moment';
import { OSPStateService } from '../services/osp-state.service';
import { forkJoin, Subscription } from 'rxjs';
import { FluctuationAllowanceService } from '../services/fluctuation-allowance/fluctuation-allowance.service';
import { parseAdjustmentType } from '../shared/adjustment-type-parser';
import { LookupService } from '../services/lookup/lookup.service';
import {
  dateFilterComparator,
  resetSortingAndFilters,
  successMessage,
  validateVanningDates,
  warningMessage,
} from '../constants';
import { logErrors } from '../shared/logger';
import * as mm from 'moment-timezone';
import { SetupActionsComponent } from '../core/setup-actions/setup-actions.component';
import { PartsActionsComponent } from './parts-actions/parts-actions.component';
import { PartsEditDialogComponent } from './parts-edit-dialog/parts-edit-dialog.component';
import { MatDialogConfig } from '@angular/material/dialog';
import { PartsDeleteDialogComponent } from './parts-delete-dialog/parts-delete-dialog.component';
import { ColDef, IDetailCellRendererParams } from 'ag-grid-community';
import { PartsInventoryService } from '../services/parts-inventory/parts-inventory.service';
import { log } from 'console';

@Component({
  selector: 'app-configuration-parts',
  templateUrl: './configuration-parts.component.html',
  styleUrls: ['./configuration-parts.component.scss']
})
export class ConfigurationPartsComponent implements OnInit, OnDestroy {
  namcValue = localStorage.getItem('namcvalue');
  userName = localStorage.getItem('UserName');

  count = 0;
  rowData = [];
  @ViewChild('osPartsGrid') osPartsGrid;
  columnDefsMaster;
  // columnDefs;
  // columnDefsTemp;
  // public defaultColDef;
  rowSelection;
  user: string;
  userRole: any;
  warningMessage = '';
  offset: number = 0;
  dataCount: number = 0;
  modalComponent = 'modal-component';
  gridOptions;
  paginationPageSize;
  paginationNumberFormatter;
  pagination = 'true';
  context: any;
  editType;
  suppressClickEdit = true;
  tooltipShowDelay = 0;
  frameworkComponents = { customTooltip: CustomTooltipComponent };
  headerCheckboxChecked: boolean = false;
  partDesc = 'Part Description';

  editEnabled = false;
  freezeStatus = false;
  filter = false;
  showToggleAll = false;
  showClear = true;
  disablePartNumber: boolean;
  disableKanban: boolean;

  // Dropdowns
  selectedKanban: any = [];
  selectedPartNo: any = [];
  // selectedDestCode: any = [];
  // selectedContainer: any = [];
  // selectedSupplier: any = [];
  selectedDock: any = [];
  selectedSpecialist: any = [];
  selectedpolicyName: any = [];
  // selectedLifecycle: any = [];
  // adjList: any = [];

  effectiveFrom: any;
  effectiveTo: any;
  adjustSubscription$: Subscription;

  // Dropdown Options
  kanbanList: any = [];
  partNoList: any = [];
  partNoListAdd: any = [];
  // destCodeList: any = [];
  // containerList: any = [];
  // supplierList: any = [];
  dockList: any = [];
  dockListForpopup: any = [];
  specialistList: any = [];
  policyNameList: any = [];
  // lifecycleList: any = [];
  rowCount = 0;
  count1 = 0;
  isEditMode = false;
  successMessage = '';


  showHideList: any = [
    {
      label: 'Part No',
      value: 'item_id',
    },
    {
      label: this.partDesc,
      value: 'part_description',
    },
    {
      label: 'Kanban',
      value: 'kanban',
    },
    {
      label: 'QPC (Quantity Per Container, Order Lot)',
      value: 'order_lot',
    },

    {
      label: 'Dock',
      value: 'dock',
    },
    {
      label: 'SPC (Specialist)',
      value: 'EMPLOYEE',
    },
    {
      label: 'CC (Container Code)',
      value: 'cont_code',
    },
    {
      label: 'Adj DT',
      value: 'adj_applied_date',
    },
    {
      label: 'Van DT',
      value: 'vanning_date',
    },
    {
      label: 'Adj Type',
      value: 'adj_type',
    },
    {
      label: 'Adj Qty',
      value: 'adj_qty',
    },
    // {
    //   label: 'Adj Code',
    //   value: 'adj_code',
    // },
    {
      label: 'Adj Reason',
      value: 'adj_reason',
    },
    {
      label: 'Updated DT',
      value: 'date_time',
    },

    {
      label: 'User',
      value: 'userid',
    },
  ];

  selectedShowHideList: any = [...this.showHideList];

  kanbanSelected = 1;

  easternDate: any;
  defaultPageSize: any = 100000;
  itContact: any;

  constructor(
    private readonly spinner: NgxSpinnerService,
    private readonly OspService: PartsInventoryService,
    private readonly excelService: ExcelService,
    private readonly gridDataService: GriddataService,
    public dialog: MatDialog,
    private readonly stateService: OSPStateService,
    private readonly flalService: FluctuationAllowanceService,
    private readonly lookupService: LookupService
  ) {
    this.context = {
      componentParent: this,
    };
  }
  numberComparator(number1, number2) {
    return number1 - number2;
  }

  dateComparator(date1, date2) {
    function monthToNum(date) {
      if (date === undefined || date === null) {
        return null;
      }

      const newDate: Date = new Date(date);

      const yearNumber = newDate.getFullYear();
      const monthNumber = newDate.getMonth();
      const dayNumber = newDate.getDate();
      const hoursNumber = newDate.getHours();
      const minutesNumber = newDate.getMinutes();

      const result =
        yearNumber * 10000 +
        monthNumber * 100 +
        dayNumber +
        hoursNumber * 60 +
        minutesNumber * 10;
      // 29/08/2004 => 20040829
      return result;
    }

    // To be used inside filter params when using date filter

    const date1Number = monthToNum(date1);
    const date2Number = monthToNum(date2);

    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }

    return date1Number - date2Number;
  }

  onPageSizeChanged() {
    if (this.gridOptions?.api) {
      this.gridOptions.api.paginationSetPageSize(
        Number(this.paginationPageSize)
      );
    }
  }

  setPageSizeToAll(pageSize?) {
    this.paginationPageSize = this.rowData.length;

    if (pageSize) {
      this.paginationPageSize = 0;
      this.rowData.length = 0;
    }

    this.onPageSizeChanged();
  }

  ngOnDestroy(): void {
    this.adjustSubscription$.unsubscribe();
  }


  public columnDefs: ColDef[]

  public defaultColDef;
  public groupDefaultExpanded = 1;
  public detailCellRendererParams


  ngOnInit(): void {

    this.detailCellRendererParams = {
      detailGridOptions: {

        columnDefs: [
          {
            headerName: 'Part No',
            field: 'part_no',
            width: 120,
            headerTooltip: 'Part Number',
            tooltipField: 'part_no',
          },
          {
            headerName: 'Part Description',
            field: 'part_description',
            width: 120,
            headerTooltip: 'Part Description',
            tooltipField: 'part_description',
          },
          {
            headerName: 'dock',
            field: 'dock',
            width: 120,
            headerTooltip: 'dock',
            tooltipField: 'dock',
          },
          {
            headerName: 'Kanban',
            field: 'kanban',
            width: 120,
            headerTooltip: 'Kanban',
            tooltipField: 'kanban',
          },
          {
            headerName: 'SPC',
            field: 'EMPLOYEE',
            width: 120,

            headerTooltip: 'Specialist',
            tooltipField: 'EMPLOYEE',
          },



        ],
        defaultColDef: {
          flex: 1,
        },
        groupDefaultExpanded: 1,
        masterDetail: true,
        detailRowHeight: 0.5,



      },
      getDetailRowData: (params) => {
        params.successCallback(params.data.children);
      },
    } as IDetailCellRendererParams;


    this.columnDefsMaster = [
      {
        headerName: '',
        field: 'rowCheckBox',
        // suppressSorting: true,
        width: 44,
        headerComponentPa44rams: {
          headerChecked: this.headerCheckboxChecked,
        },
        headerComponentFramework: HeaderCheckboxComponent,
        floatingFilterComponentFramework: GridRefreshComponent,
        floatingFilterComponentParams: { suppressFilterButton: true },

        cellRendererFramework: RowCheckboxComponent,
        floatingFilter: true,
        pinned: 'left',
      },
      {
        headerName: 'Actions',
        cellRendererFramework: PartsActionsComponent,
        field: 'actions',
        width: 124,
        sortable: false,
        floatingFilter: false,
        pinned: 'left',
      },

      {
        headerName: 'Policy Name',
        field: 'policy_name',
        width: 170,
        floatingFilter: true,
        tooltipField: 'policy_name',
        headerTooltip: 'Policy Name',
        cellRenderer: "agGroupCellRenderer"
      },
      {
        headerName: 'Policy Description',
        field: 'description',
        sortable: true,
        width: 250,
        floatingFilter: true,
        tooltipField: 'description',
        headerTooltip: 'Policy descripton'

      },
      {
        headerName: 'Effective From',
        field: 'yearweek_from',
        sortable: true,
        width: 120,
        floatingFilter: true,
        tooltipField: 'yearweek_from',
        headerTooltip: 'Effective year week from'

      },
      {
        headerName: 'Effective To',
        field: 'yearweek_to',
        sortable: true,
        width: 120,
        floatingFilter: true,
        tooltipField: 'yearweek_from',
        headerTooltip: 'Effective year week To'

      },
      {
        headerName: 'Min Days',
        field: 'min_days',
        sortable: true,
        width: 120,
        floatingFilter: true,
        tooltipField: 'min_days',
        headerTooltip: 'Minimum days'

      },
      {
        headerName: 'Max Days',
        field: 'max_days',
        sortable: true,
        width: 120,
        floatingFilter: true,
        tooltipField: 'max_days',
        headerTooltip: 'Maximum days'

      },
      {
        headerName: 'Min Boxes',
        field: 'min_boxes',
        sortable: true,
        width: 120,
        floatingFilter: true,
        tooltipField: 'min_boxes',
        headerTooltip: 'Minimum boxes'

      },

      {
        headerName: 'Max Boxes',
        field: 'max_boxes',
        sortable: true,
        width: 120,
        floatingFilter: true,
        tooltipField: 'max_boxes',
        headerTooltip: 'Maximum boxes'

      },


    ];

    this.columnDefs = [...this.columnDefsMaster];

    this.setPageSizeToAll(this.defaultPageSize);

    this.rowSelection = 'multiple';
    this.editType = 'fullRow';
    this.gridOptions = this.gridDataService.getGridOptions();
    this.gridOptions.onGridReady = function (params) {
      this.gridApi = params.api;
    };
    this.adjustSubscription$ = this.stateService
      .getNamc()
      .subscribe((observable) => {
        this.initializeData();
      });


  }



  initializeData() {
    this.spinner.show();
    this.rowData = [];
    this.spinner.show();

    this.namcValue = localStorage.getItem('namcvalue');

    if (localStorage.getItem('workdayId')) {
      this.user = localStorage.getItem('workdayId');
    }
    if (localStorage.getItem('UserRoles')) {
      this.userRole = localStorage.getItem('UserRoles');
    }

    this.loadDrop();
    this.onResetDropDown();


    this.lookupService.getEasternDate().subscribe({
      error: this.errorCallback,
      next: (res) => {
        this.easternDate = res.body.data;
      },
    });
  }
  checkIfAnySelected() {



    if (
      this.selectedDock.length > 0
    ) {
      return true;
    }

    return false;
  }





  resetGrid() {
    if (this.osPartsGrid) {
      resetSortingAndFilters(this.osPartsGrid);

      return;
    }
  }

  onSearch(offset) {
    //this.refreshView()
    if (this.osPartsGrid) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();

      this.resetGrid();
    }

    this.removeMessage();

    if (offset === 0) {
      this.setPageSizeToAll(this.defaultPageSize);
      this.rowData = [];
      this.osPartsGrid.gridOptions.api.setRowData(this.rowData);

      const { valid, error } = validateVanningDates({
        vanningFrom: this.effectiveFrom,
        vanningTo: this.effectiveTo,
      });

      if (!valid) {
        this.warningMessage = error;
        window.scroll(0, 0);

        this.spinner.hide();

        return;
      }

      this.offset = 0;
      this.spinner.show();
    }

    if (!this.checkIfAnySelected()) {
      this.rowData = [];
      this.warningMessage = warningMessage.rundownSearchError;

      window.scroll(0, 0);
      this.spinner.hide();
      return false;
    }

    let kanban;
    let selectedPartNo;
    // let selectedDestCode;
    // let selectedContainer;
    // let selectedSupplier;
    let selectedDock;
    let selectedSpecialist;
    let selectedpolicyName;


    if (this.selectedKanban.length > 0) {
      if (!this.selectedKanban.some((a) => a.kanban === 'ALL')) {
        kanban = [];
        this.selectedKanban.forEach((element) => {
          kanban.push(element.kanban);
        });
      }
    }

    if (this.selectedPartNo.length > 0) {
      if (!this.selectedPartNo.some((a) => a.item_id === 'ALL')) {
        selectedPartNo = [];
        this.selectedPartNo.forEach((element) => {
          selectedPartNo.push(element.item_id);
        });
      }
    }

    // if (this.selectedDestCode.length > 0) {
    //   if (!this.selectedDestCode.some((a) => a.dest_code === 'ALL')) {
    //     selectedDestCode = [];
    //     this.selectedDestCode.forEach((element) => {
    //       selectedDestCode.push(element.dest_code);
    //     });
    //   }
    // }

    // if (this.selectedContainer.length > 0) {
    //   if (!this.selectedContainer.some((a) => a.cont_code === 'ALL')) {
    //     selectedContainer = [];
    //     this.selectedContainer.forEach((element) => {
    //       selectedContainer.push(element.cont_code);
    //     });
    //   }
    // }

    // if (this.selectedSupplier.length > 0) {
    //   if (!this.selectedSupplier.some((a) => a.customer_supp === 'ALL')) {
    //     selectedSupplier = [];
    //     this.selectedSupplier.forEach((element) => {
    //       selectedSupplier.push(element.customer_supp);
    //     });
    //   }
    // }

    if (this.selectedDock.length > 0) {
      if (!this.selectedDock.some((a) => a.dock === 'ALL')) {
        selectedDock = [];
        this.selectedDock.forEach((element) => {
          selectedDock.push(element.dock);
        });
      }

      // selectedDock = [];
      // this.selectedDock.forEach((element) => {
      //   selectedDock.push(element.dock);
      // });
    }

    if (this.selectedpolicyName.length > 0) {
      if (!this.selectedpolicyName.some((a) => a.dock === 'ALL')) {
        selectedpolicyName = [];
        this.selectedpolicyName.forEach((element) => {
          selectedpolicyName.push(element.policy_name);
        });
      }
    }

    if (this.selectedSpecialist.length > 0) {
      if (!this.selectedSpecialist.some((a) => a.EMPLOYEE === 'ALL')) {
        selectedSpecialist = [];
        this.selectedSpecialist.forEach((element) => {
          selectedSpecialist.push(element.EMPLOYEE);
        });
      }
      // selectedSpecialist = [];
      // this.selectedSpecialist.forEach((element) => {
      //   selectedSpecialist.push(element.EMPLOYEE);
      // });
    }



    const data = {
      offset: offset,
      limit: 12000,
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      kanban: kanban,
      part_no: selectedPartNo,
      policy_name: selectedpolicyName,
      dock: selectedDock,
      spc: selectedSpecialist,
      eff_from: this.effectiveFrom,
      eff_to: this.effectiveTo,
      currenteasternDate: this.easternDate,
    };

    this.OspService.getpartInventoryGrid(data).subscribe({
      error: this.errorCallback,
      next: (res) => {
        if (res.body && res.body.data) {
          this.rowData.push(...res.body.data);
          this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
          this.dataCount = parseInt(res.body.data.rowCount);
          const records = this.dataCount - (this.offset + 1) * 12000;
          if (records >= 1) {
            this.offset++;
            this.onSearch(this.offset * 12000);
          } else {
            this.spinner.hide();
          }
        } else {
          this.rowData = [];
          this.spinner.hide();
        }

        this.setPageSizeToAll();
      },
    });
    return true;
  }

  loadDrop() {
    this.spinner.show();

    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
    };

    const lookupSubscriptions = [
      this.lookupService.getKanban(),
      this.lookupService.getPartNumber(),
      this.lookupService.getSpecialist(),
      this.lookupService.getDock(),
      // this.flalService.getMaxDate(data),
      // this.lookupService.getUserShowHideColumns(),
      this.OspService.getpolicyName(data),
    ];
    forkJoin(lookupSubscriptions).subscribe({
      next: (res) => {
        //if kanbanList, add ALL option
        this.kanbanList = [];
        this.partNoList = [];
        this.partNoListAdd = [];

        this.dockList = [];
        this.dockListForpopup = [];
        this.specialistList = [];
        this.policyNameList = [];
        if (res[0].body.data) {
          this.kanbanList.push({ kanban: 'ALL' });
        }

        this.kanbanList.push(...res[0].body.data);

        //if item id list, add ALL option
        if (res[1].body.data) {
          this.partNoList.push({ item_id: 'ALL' });
        }



        if (res[3].body.data) {
          this.dockList.push({ dock: 'ALL' });
        }
        // if (res[7].body.data) {
        //   this.adjList.push({ adj_type: 'ALL' });
        // }
        if (res[2].body.data) {
          this.specialistList.push({ EMPLOYEE: 'ALL' });
        }
        if (res[4].body) {
          this.policyNameList.push({ policy_name: 'ALL' });
        }

        this.partNoList.push(...res[1].body.data);
        this.partNoListAdd.push(...res[1].body.data)

        this.specialistList.push(...res[2].body.data);
        this.dockList.push(...res[3].body.data);
        this.dockListForpopup.push(...res[3].body.data)
        this.policyNameList.push(...res[4].body);



        this.spinner.hide();


        // const { adjs_columns } = res[7].body.showHideColumnsResponse;

        // try {
        //   const hideItems = JSON.parse(adjs_columns);

        //   if (typeof hideItems === 'object' && hideItems.length > 0) {
        //     this.selectedShowHideList = this.showHideList.filter(
        //       (sItem) =>
        //         !hideItems.map((item) => item.value).includes(sItem.value)
        //     );



        //     this.onShowHideChange({
        //       value: this.selectedShowHideList,
        //       onInit: true,
        //     });
        //   }
        // } catch (error) {}

        this.spinner.hide();
      },
      error: this.errorCallback,
    });
  }

  onKeyPress(event: KeyboardEvent) {
    const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];
    const isNumber = /^[0-9]$/.test(event.key);

    // Allow number keys, allowed keys, and prevent default for others
    if (!isNumber && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  errorCallback = (error) => {
    logErrors(error);
    this.spinner.hide();

    this.warningMessage = 'Something went wrong! Please contact support team.';
    this.rowData = [];
    this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
  };

  onResetDropDown() {
    this.removeMessage();
    this.selectedKanban = [];
    this.selectedPartNo = [];

    this.selectedDock = [];
    this.selectedSpecialist = [];
    this.selectedpolicyName = [];
    this.effectiveFrom = '';
    this.effectiveTo = '';



    if (this.osPartsGrid) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();
      this.resetGrid();
    }

    this.setPageSizeToAll(this.defaultPageSize);

    this.rowData = [];
    this.disableKanban = false;
    this.disablePartNumber = false;
    this.offset = 0;
  }
  // toogleHeaderCheckBox(checked) {
  //   if (checked) {
  //     const rowCount = this.osPartsGrid.gridOptions.rowData.length;
  //     const selectedRowCount = this.osPartsGrid.gridOptions.rowData.filter(
  //       (x) => x.rowCheckBox === true
  //     ).length;
  //     if (rowCount === selectedRowCount) {
  //       this.osPartsGrid.gridOptions.columnDefs[0].headerComponentFramework.checked =
  //         checked;
  //       this.osPartsGrid.gridOptions.api.refreshHeader();
  //     }
  //   } else {
  //     this.osPartsGrid.gridOptions.columnDefs[0].headerComponentFramework.checked =
  //       checked;
  //     this.osPartsGrid.gridOptions.api.refreshHeader();
  //   }
  // }

  toggleHeaderCheckbox(checked) {
    if (!checked) {
      this.headerCheckboxChecked = false;

      this.osPartsGrid.gridOptions.api.refreshHeader();

      return;
    }

    const rowCount = this.osPartsGrid.gridOptions.rowData.length;
    const selectedRowCount = this.osPartsGrid.gridOptions.rowData.filter(
      (x) => x.rowCheckBox === true
    ).length;

    if (rowCount === selectedRowCount) {
      this.headerCheckboxChecked = true;

      this.osPartsGrid.gridOptions.api.refreshHeader();
    }
  }
  userCheckChanged(checked, rowIndex) {
    this.spinner.show();
    const rowNode =
      this.osPartsGrid?.gridOptions?.api?.getDisplayedRowAtIndex(rowIndex);
    if (checked === true) {
      rowNode.setSelected(true);
      if (this.isEditMode) {
        rowNode.data.checkedEditMode = true;
      }
      rowNode.data.rowCheckBox = true;
    } else {
      rowNode.setSelected(false);
      rowNode.data.checkedEditMode = false;
      rowNode.data.rowCheckBox = false;
    }

    this.gridOptions.getRowStyle = function (params) {
      if (params.node.rowIndex === rowIndex) {
        const color = checked ? '#E4ECF0' : 'white';
        return { background: color };
      }
      return { background: 'white' };
    };

    rowNode.setData(rowNode.data);
    this.toggleHeaderCheckbox(checked);
    this.spinner.hide();
  }
  headerCheckChanged(event) {
    this.headerCheckboxChecked = event.checked;
    const headerChecked = event.checked;
    const isEditable = this.isEditMode;
    this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
      if (headerChecked) {
        if (isEditable) {
          rowNode.data.checkedEditMode = true;
        }
        rowNode.data.rowCheckBox = true;
      } else {
        rowNode.data.rowCheckBox = false;
        rowNode.data.checkedEditMode = false;
      }
      rowNode.setData(rowNode.data);
    });
  }
  adjs_columns
  onShowHideChange(ev: any) {
    this.osPartsGrid.gridOptions.columnApi.setColumnsVisible(
      [...this.showHideList.map((e) => e.value)],
      true
    );

    const hideItems = this.showHideList.filter((item) => {
      return !ev.value.map((sItem) => sItem.value).includes(item.value);
    });

    this.osPartsGrid.gridOptions.columnApi.setColumnsVisible(
      [...hideItems.map((e) => e.value)],
      false
    );

    if (ev.onInit) return;

    this.adjs_columns = hideItems;
    if (!hideItems.length) this.adjs_columns = [{}];


  }

  saveShowHide() {
    this.spinner.show();

    let adjs_columns = this.adjs_columns;
    if (adjs_columns) {
      this.lookupService
        .updateUserShowHideColumns({ adjs_columns })
        .subscribe({
          error: this.errorCallback,
          next: (res) => {
            console.log(res);
            this.spinner.hide();

          },
        });
    }
    else {
      this.spinner.hide()
    }
  }

  onKanbanChange(): void {
    if (this.selectedKanban.length >= 1) {
      if (
        this.selectedKanban[this.selectedKanban.length - 1].kanban === 'ALL'
      ) {
        this.selectedKanban = [];
        this.selectedKanban.push(this.kanbanList[0]);
      } else {
        let indexAll = this.selectedKanban.findIndex(
          (data) => data.kanban === 'ALL'
        );

        if (indexAll > -1) {
          this.selectedKanban.splice(indexAll, 1);
        }
      }
    }
    if (this.selectedKanban.length > 0) {
      this.disablePartNumber = true;
    } else {
      this.disablePartNumber = false;
    }
  }
  onPartNoChange(event): void {
    if (this.selectedPartNo.length >= 1) {
      if (
        this.selectedPartNo[this.selectedPartNo.length - 1].item_id === 'ALL'
      ) {
        this.selectedPartNo = [];
        this.selectedPartNo.push(this.partNoList[0]);
      } else {
        let indexAll = this.selectedPartNo.findIndex(
          (data) => data.item_id === 'ALL'
        );

        if (indexAll > -1) {
          this.selectedPartNo.splice(indexAll, 1);
        }
      }
    }

    if (this.selectedPartNo.length > 0) {
      this.disableKanban = true;
    } else {
      this.disableKanban = false;
    }
  }

  // onDestCodeChange(): void {
  //   if (this.selectedDestCode.length >= 1) {
  //     if (
  //       this.selectedDestCode[this.selectedDestCode.length - 1].dest_code ===
  //       'ALL'
  //     ) {
  //       this.selectedDestCode = [];
  //       this.selectedDestCode.push(this.destCodeList[0]);
  //     } else {
  //       let indexAll = this.selectedDestCode.findIndex(
  //         (data) => data.dest_code === 'ALL'
  //       );

  //       if (indexAll > -1) {
  //         this.selectedDestCode.splice(indexAll, 1);
  //       }
  //     }
  //   }
  // }
  // containerCodeChange(): void {
  //   if (this.selectedContainer.length >= 1) {
  //     if (
  //       this.selectedContainer[this.selectedContainer.length - 1].cont_code ===
  //       'ALL'
  //     ) {
  //       this.selectedContainer = [];
  //       this.selectedContainer.push(this.containerList[0]);
  //     } else {
  //       let indexAll = this.selectedContainer.findIndex(
  //         (data) => data.cont_code === 'ALL'
  //       );

  //       if (indexAll > -1) {
  //         this.selectedContainer.splice(indexAll, 1);
  //       }
  //     }
  //   }
  // }

  // supplierChange(): void {
  //   if (this.selectedSupplier.length >= 1) {
  //     if (
  //       this.selectedSupplier[this.selectedSupplier.length - 1]
  //         .customer_supp === 'ALL'
  //     ) {
  //       this.selectedSupplier = [];
  //       this.selectedSupplier.push(this.supplierList[0]);
  //     } else {
  //       let indexAll = this.selectedSupplier.findIndex(
  //         (data) => data.customer_supp === 'ALL'
  //       );

  //       if (indexAll > -1) {
  //         this.selectedSupplier.splice(indexAll, 1);
  //       }
  //     }
  //   }
  // }

  dockChange(): void {
    if (this.selectedDock.length >= 1) {
      if (this.selectedDock[this.selectedDock.length - 1].dock === 'ALL') {
        this.selectedDock = [];
        this.selectedDock.push(this.dockList[0]);
      } else {
        let indexAll = this.selectedDock.findIndex(
          (data) => data.dock === 'ALL'
        );

        if (indexAll > -1) {
          this.selectedDock.splice(indexAll, 1);
        }
      }
    }
  }

  policyNameChange(): void {
    if (this.selectedpolicyName.length >= 1) {
      if (this.selectedpolicyName[this.selectedpolicyName.length - 1].policy_name === 'ALL') {
        this.selectedpolicyName = [];
        this.selectedpolicyName.push(this.policyNameList[0]);
      } else {
        let indexAll = this.selectedpolicyName.findIndex(
          (data) => data.policy_name === 'ALL'
        );

        if (indexAll > -1) {
          this.selectedpolicyName.splice(indexAll, 1);
        }
      }
    }
  }


  specialistChange(): void {
    if (this.selectedSpecialist.length >= 1) {
      if (
        this.selectedSpecialist[this.selectedSpecialist.length - 1].EMPLOYEE ===
        'ALL'
      ) {
        this.selectedSpecialist = [];
        this.selectedSpecialist.push(this.specialistList[0]);
      } else {
        let indexAll = this.selectedSpecialist.findIndex(
          (data) => data.EMPLOYEE === 'ALL'
        );

        if (indexAll > -1) {
          this.selectedSpecialist.splice(indexAll, 1);
        }
      }
    }
  }

  getModifiedRowData(): any {
    return this.rowData.filter((row) => row.rowCheckBox);
  }
  exportAsXLSX(): void {
    this.removeMessage();

    this.spinner.show();

    // eastern time api

    const easternCurrentDate = String(
      mm().tz('US/Michigan').format('YYYY-MM-DD_HH:mm:ss')
    );
    console.log(easternCurrentDate);
    let data = [];

    if (this.headerCheckboxChecked) {
      this.osPartsGrid.gridOptions.api.forEachNodeAfterFilter((node) => {
        data.push(node.data);
      });
    } else {
      data = this.getModifiedRowData();
    }

    if (data.length < 1) {
      this.spinner.hide();
      this.warningMessage = warningMessage.noRowsSelected;

      window.scroll(0, 0);
    } else {
      setTimeout(async () => {
        if (this.rowData.length > 0) {
          const exportData: any = [];

          data.forEach((e) => {
            const obj = {
              PartNO: e.item_id,
              partdescription: e.part_description,
              kanban: e.kanban,
              Qpc: e.order_lot,
              dock: e.dock,
              Specialist: e.EMPLOYEE,
              container: e.cont_code,
              adjdt: e.adj_applied_date,
              vandt: e.vanning_date,
              adjtype: e.adj_type,
              adjquantity: parseInt(e.adj_qty),
              adjreason: e.adj_reason,
              updatedt: e.date_time,
              User: e.userid,
            };
            exportData.push(obj);
          });

          const headers = [
            'PART NO',
            'PART DESCRIPTION',
            'KANBAN',
            'QPC',
            'DOCK',
            'SPC',
            'CC',
            'ADJ DT',
            'VAN DT',
            'ADJ TYPE',
            'ADJ QTY',
            'ADJ REASON',
            'UPDATED DT',
            'USER',
          ];

          const timestamp = easternCurrentDate

            .split('.')[0]
            .replace('T', '_')
            .split('')

            .filter((d) => d !== '-' && d !== ':')
            .join('');

          this.excelService.exportAsExcelFile(
            exportData,
            'AdjustmentInquiry_' + timestamp,

            headers
          );
        }
        this.spinner.hide();
      }, 1000);
    }
  }
  removeMessage() {
    this.warningMessage = '';
    this.successMessage = '';
  }






  userDeleteChanged(rowIndex) {
    const rowNode =
      this.osPartsGrid.gridOptions.api.getDisplayedRowAtIndex(rowIndex);

    // this.mdid = rowNode.data.master_id;
    // this.mdkey = rowNode.data.key_item.trim();
    this.opendeleteDialog(rowNode.data);
  }

  userEdit(rowIndex) {
    const rowNode =
      this.osPartsGrid.gridOptions.api.getDisplayedRowAtIndex(rowIndex);

    // this.mid = rowNode.data.master_id;
    // this.mname = rowNode.data.name;
    // this.mkey = rowNode.data.key_item.trim();
    // this.mdata = rowNode.data.data_item.trim();
    this.openeditDialog(rowNode.data);
  }

  //delete dialog popup
  opendeleteDialog(dataTODelete) {
    console.log("dataTODelete", dataTODelete);
    var data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      userid: this.userName,
      policy_id: dataTODelete?.policy_id
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = this.modalComponent;

    dialogConfig.width = '390px';
    const dialogRef = this.dialog.open(PartsDeleteDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((res) => {
      this.removeMessage();
      if (res === 'cancel') {
        return;
      }
      if (res === 'save') {
        console.log("inside save")
        this.OspService.deletePolicy(data).subscribe({
          error: this.errorCallback,
          next: (data) => {
            console.log(data)
            if (data.body.table1DeletedCount >= 1 || data.body.table2DeletedCount >= 1) {
              this.onSearch(0);
              this.successMessage = successMessage.deleteRecord;
              this.spinner.hide();
              window.scroll(0, 0);
            } else {
              this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
              this.rowData = [];
              this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
              this.spinner.hide();
            }
          },
        });

        // return;
      }

      // this.OspService.deleteMasterDetails(
      //   res.business_entity,
      //   this.userRole,
      //   this.user,
      //   this.mdid,
      //   this.mdkey
      // ).subscribe({
      //   error: this.errorCallback,
      //   next: (data) => {
      //     if (data.body.masterdeleteResponse === 1) {
      //       this.successMessage = successMessage.deleteRecord;
      //       this.spinner.hide();
      //       window.scroll(0, 0);
      //       this.loadMasterDetails();
      //     } else {
      //       this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
      //       this.rowData = [];
      //       this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
      //       this.master_id = '';
      //       this.m_name = '';
      //       this.spinner.hide();
      //     }
      //   },
      // });
    });
  }
  //edit dialog popup
  openeditDialog(data) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = this.modalComponent;
    dialogConfig.width = '500px';
    dialogConfig.data = {
      partNoList: this.partNoListAdd,
      dockList: this.dockList,
      data,
      mode: 'edit',
    };

    const dialogRef = this.dialog.open(PartsEditDialogComponent, dialogConfig);
    const editGetResponse =
      dialogRef.componentInstance.editMasterEventEmitter.subscribe((res) => {
        //this.removeMessage();
        console.log("Edit res---", res);
        var data = {
          business_entity: this.namcValue,
          workid: this.user,
          user_role: this.userRole,
          userid: this.userName,
          policy_id: dialogConfig.data.data.policy_id,
          dock: res.dock,
          policy_name: res.policyName,
          description: res.description,
          parts: res.part_no || [],
          min_days: res.minDays,
          max_days: res.maxDays,
          yearweek_to: res.effectiveTo === null ? '' : res.effectiveTo,
          yearweek_from: res.effectiveFrom,
          min_boxes: res.minBoxes,
          max_boxes: res.maxBoxes,
          plant: '01',

          data: {

            dock: res.dock,
            workid: this.user,
            policy_name: res.policyName,
            description: res.description,
            parts: res.part_no || [],
            min_days: res.minDays,
            max_days: res.maxDays,
            yearweek_to: res.effectiveTo === null ? '' : res.effectiveTo,
            yearweek_from: res.effectiveFrom,
            min_boxes: res.minBoxes,
            max_boxes: res.maxBoxes,
            policy_id: dialogConfig.data.data.policy_id,
            plant: '01',
          }

        }

        this.OspService.editpolicy(
          data
        ).subscribe({
          error: this.errorCallback,
          next: (response) => {
            console.log("editpolicy", response);
            this.spinner.hide();
            const updatedRows = response.body.updatedRows;
            console.log("length", updatedRows.length)
            if (updatedRows.length > 0) {
              this.successMessage = successMessage.policyUpdated;
              this.rowData = updatedRows;
              this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
            } else {
              this.warningMessage = `${warningMessage.reportsNotUpdated}  ${this.itContact}.`;
            }

            window.scroll(0, 0);
            this.setPageSizeToAll();
            window.scroll(0, 0);
          },
        });
      });
    dialogRef.afterClosed().subscribe((res) => {
      editGetResponse.unsubscribe();
    });
  }
  // add dialog pop up
  openDialog() {
    this.removeMessage();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = this.modalComponent;
    dialogConfig.width = '650px';
    dialogConfig.height = '500px';
    dialogConfig.data = {
      partNoList: this.partNoListAdd,
      dockList: this.dockListForpopup,
      mode: 'add',
    };
    const dialogRef = this.dialog.open(PartsEditDialogComponent, dialogConfig);
    const addGetResponse =
      dialogRef.componentInstance.addMasterEventEmitter.subscribe((res) => {
        console.log("res---", res);
        var data = {
          business_entity: this.namcValue,
          workid: this.user,
          user_role: this.userRole,
          userid: this.userName,
          dock: res.dock,
          policy_name: res.policyName,
          description: res.description,
          part_number: res.part_no || [],
          min_days: res.minDays,
          max_days: res.maxDays,
          yearweek_to: res.effectiveTo,
          yearweek_from: res.effectiveFrom,
          min_boxes: res.minBoxes,
          max_boxes: res.maxBoxes,
          plant: '01',

          data: {
            dock: res.dock,
            policy_name: res.policyName,
            description: res.description,
            part_number: res.part_no || [],
            min_days: res.minDays,
            max_days: res.maxDays,
            yearweek_to: res.effectiveTo,
            yearweek_from: res.effectiveFrom,
            min_boxes: res.minBoxes,
            max_boxes: res.maxBoxes,
            plant: '01'
          }
        }
        console.log("Add policy data send to api..." + data);

        this.OspService.addpolicy(
          data
        ).subscribe({
          error: this.errorCallback,
          next: (response) => {
            console.log("addpolicy", response);
            this.spinner.hide();
            const updatedRows = response.body.updatedRows;
            console.log("length", updatedRows.length)
            if (updatedRows.length > 0) {
              this.successMessage = successMessage.policyAdded;
              this.rowData = updatedRows;
              this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
              // this.osPartsGrid
            } else {
              this.warningMessage = `${warningMessage.reportsNotUpdated}  ${this.itContact}.`;
            }

            window.scroll(0, 0);
            this.setPageSizeToAll();



            // if (error) {
            //   this.warningMessage = '';
            //   // this.successMessage = '';

            //   if (message?.includes('duplicate')) {
            //     this.warningMessage = warningMessage.duplicateRecord;
            //   } else {
            //     this.warningMessage = `${warningMessage.apiLogicFail}`;
            //   }
            // } else {
            //   this.warningMessage = '';

            //   // this.successMessage = warningMessage.addRecord;
            //   // this.loadMasterDetails();
            // }
            window.scroll(0, 0);
          },
        });
      });
    dialogRef.afterClosed().subscribe((res) => {
      addGetResponse.unsubscribe();
    });
  }
}
