import { Component } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'grid-text-editor',
  template: `
    <label *ngIf="showInput">{{ value }} </label>
    <input
      *ngIf="!showInput"
      #input
      style="width:93px"
      type="date"
      [(ngModel)]="value"
      (keydown)="stopTyping()"
      class="grid-cell-edit-layout "
      (change)="textUpdated()"
    />
  `,
  styles: [
    `
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    `,
  ],
})
export class GridShiftsDateEditorComponent {
  public params: any;
  showInput = true;
  value: any;
  isEmpty = true;
  disableDate = '';
  reportdate = new Date();
  constructor() {}
  agInit(params: any): void {
    this.params = params;
    if (params.column === 'toDate') {
      params.data.toDate = this;
      this.value = params.data.to_date;

      this.disableDate = moment(this.value)?.format('YYYY-MM-DD');
      // this.disableDate =  moment(this.value, moment.ISO_8601).format('MM/DD/YYYY');
      console.log('disableDate', this.disableDate);

      if (this.value) {
        this.isEmpty = false;
        this.value = moment(this.value).format('MM/DD/YYYY');
      } else {
        this.isEmpty = true;
      }
    }
    this.params = params;
    

    if (params.column === 'fromDate') {
      params.data.fromDate = this;
      this.value = params.data.from_date;
      console.log(this.value,"54")
      this.disableDate = moment(this.reportdate).format('YYYY-MM-DD');
      console.log('disableDate', this.disableDate);

      if (this.value) {
        this.isEmpty = false;
        this.value = moment(this.value).format('MM/DD/YYYY');
      } else {
        this.isEmpty = true;
      }
    }

    this.params = params;

    if (
      this.params.data.checkedEditMode 
    ) {
      this.showInput = false;
      this.value = moment(this.value).format('YYYY-MM-DD');
    } else {
      this.showInput = true;
      if (this.value) {
        this.value = moment(this.value).format('MM/DD/YYYY');
      }
    }
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  public textUpdated() {
    if (this.params.column === 'toDate') {
      if (this.value !== this.params.data.toDate) {
        this.params.data.toDate = this.value;
        this.params.data.isToDate = true;
      }
    } else {
      this.params.data.to_date = this.value;
    }
    if (this.params.column === 'fromDate') {
      if (this.value !== this.params.data.fromDate) {
        this.params.data.fromDate = this.value;
        this.params.data.isFromDate = true;
      }
    } else {
      this.params.data.from_date = this.value;
    }
  }
  onKeyPress(event) {
    if (event.target.value.length <= 4) {
      return true;
    } else {
      return false;
    }
  }
  stopTyping() {
    return false;
  }
}