import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { log } from 'console';
import { getUniqueByKey } from 'src/app/constants';
import { PartsInventoryService } from 'src/app/services/parts-inventory/parts-inventory.service';
import { logErrors } from 'src/app/shared/logger';


@Component({
  selector: 'app-parts-edit-dialog',
  templateUrl: './parts-edit-dialog.component.html',
  styleUrls: ['./parts-edit-dialog.component.scss']
})
export class PartsEditDialogComponent implements OnInit {

  addMasterEventEmitter = new EventEmitter();
  editMasterEventEmitter = new EventEmitter();
  namcValue = localStorage.getItem('namcvalue');
  user = localStorage.getItem('workdayId');
  userRole = localStorage.getItem('UserRoles')
  resData: any
  warningMessage = '';
  errorMessage: string | null = null;
  master_id: any;
  master_name: any;
  key_item: any;
  data_item: any;
  business_entity: any;
  new_key_item: any;
  cstatus: any;
  showaddpopup: boolean = false;
  showeditpopup: boolean = false;
  popupname: any;
  dock: any;
  policyName: any;
  description: any;
  dataItem: any;
  enableError: boolean = false;
  userForm: UntypedFormGroup;

  addOrEditform: UntypedFormGroup = this.formBuilder.group({
    dock: ['', Validators.required],
    policyName: ['', Validators.required],
    description: [''],
    part_no: [''],
    maxDays: ['', Validators.required],
    minDays: ['', Validators.required],
    effectiveTo: [''],
    effectiveFrom: ['', Validators.required],
    minBoxes: ['', Validators.required],
    maxBoxes: ['', Validators.required]
  }, { validator: [this.minDaysLessThanMaxDays, this.minBoxesLessThanMaxBoxes] },

  );


  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private readonly dialogeRef: MatDialogRef<PartsEditDialogComponent>,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly OspService: PartsInventoryService,
  ) { }
  get addOrEdit() {
    return this.addOrEditform.controls;
  }

  // Custom validator to check minDays if maxDays is entered
  // minMaxDaysValidator(formGroup: UntypedFormGroup): { [key: string]: boolean } | null {
  //   const maxDays = formGroup.get('maxDays')?.value;
  //   const minDays = formGroup.get('minDays')?.value;
  // console.log('entered')
  //   if (!maxDays && minDays) {
  //     // consol
  //     return { minDaysRequired: true };
  //   }

  //   return null;
  // }


  ngOnInit(): void {

    this.business_entity = localStorage.getItem('namcvalue');

    console.log("data---", this.data);


    if (this.data?.mode === 'edit') {

      if (this.data.data) {

        const newArray = this.data.data.children.map(item => ({
          item_id: item.part_no // Push part_no value under the new key 'item_id'
        }));

        this.addOrEditform.patchValue({
          dock: { dock: this.data.data.dock },
          policyName: this.data.data.policy_name,
          description: this.data.data.description || '',
          maxDays: this.data.data.max_days,
          minDays: this.data.data.min_days,
          effectiveTo: this.data.data.yearweek_to,
          effectiveFrom: this.data.data.yearweek_from,
          minBoxes: this.data.data.min_boxes,
          maxBoxes: this.data.data.max_boxes,
          part_no: newArray
        });
        this.addOrEditform.get('dock')?.disable();
      }

    }
  }
  // atLeastOnePhoneRequired(group : UntypedFormGroup) : {[s:string ]: boolean} {
  //   if (group) {
  //     if((group.controls['maxDays'].value) && (group.controls['minDays'].value) || 
  //     (group.controls['minBoxes'].value) && (group.controls['maxBoxes'].value)) {
  //       return null;
  //     }
  //   }
  //   // return { group.controls.maxDays.errors : true};
  // }


  // updateMinDaysValidation(control1: string, control2: string, value: number): void {

  //   if (value === 1 && this.addOrEditform.controls[control1]?.value) {
  //     console.log(this.addOrEditform.controls[control1]?.value, '111')
  //     this.addOrEditform.controls[control2]?.setValidators([Validators.required]);
  //   } else if (value === 2 && this.addOrEditform.controls[control1]?.value) {
  //     this.addOrEditform.controls[control2]?.setValidators([Validators.required]);
  //   } else {
  //     this.addOrEditform.controls[control1]?.clearValidators();
  //     this.addOrEditform.controls[control2]?.clearValidators();
  //   }
  //   this.addOrEditform.controls[control1]?.updateValueAndValidity();
  //   this.addOrEditform.controls[control2]?.updateValueAndValidity();
  // }



  //check validation min days/boxes cannot be greater than max days/boxes 
  minDaysLessThanMaxDays(group: UntypedFormGroup) {debugger

    const minDays = group.get('minDays').value;
    const maxDays = group.get('maxDays').value;
    return  minDays && maxDays && minDays > maxDays ? { minDaysMoreThanMaxDays: true }: null;
  }

  minBoxesLessThanMaxBoxes(group: UntypedFormGroup) {debugger

    const minBoxes = group.get('minBoxes').value;
    const maxBoxes = group.get('maxBoxes').value;
    return  minBoxes && maxBoxes && minBoxes > maxBoxes ? { minBoxesMoreThanMaxBoxes: true }: null;
  }

  submitted: boolean = false;
  addMasterDetails() {
    console.log("adddata---", this.addOrEditform, this.addOrEdit);

    if (this.addOrEditform.invalid) {
      this.submitted = true;
      return;
    }

    const addEmittedData = {
      dock: this.addOrEdit.dock.value.dock,
      policyName: this.addOrEdit.policyName.value,
      description: this.addOrEdit.description.value,
      part_no: this.addOrEdit.part_no.value,
      minDays: this.addOrEdit.minDays.value,
      maxDays: this.addOrEdit.maxDays.value,
      effectiveTo: this.addOrEdit.effectiveTo.value,
      effectiveFrom: this.addOrEdit.effectiveFrom.value,
      minBoxes: this.addOrEdit.minBoxes.value,
      maxBoxes: this.addOrEdit.maxBoxes.value,
      // plant:'01'
    };


    const offset = 0;
    let selectedPartNo;
    let isCall = false;
    if (addEmittedData.part_no.length > 0) {debugger
      isCall = true;
      selectedPartNo = [];
      addEmittedData.part_no.forEach((element) => {
        selectedPartNo.push(element.item_id);
      });
    }
    // call api to check validation effective from dataes have add parts number present in db or not if not it will allow to save 
    if (isCall) {debugger
      const data = {
        offset: offset,
        limit: 12000,
        business_entity: this.namcValue,
        workid: this.user,
        part_no: selectedPartNo,
        dock: [addEmittedData.dock],
        eff_from: addEmittedData.effectiveFrom,
        eff_to: addEmittedData.effectiveTo,
        user_role: this.userRole,
      };
      this.OspService.getpartInventoryGrid(data).subscribe({
        error: this.errorCallback,
        next: (res) => {

          if (res.body.data.length == 0) {
            this.errorMessage = null;
            console.log("addMaster--", this.addOrEdit, addEmittedData);
            this.addMasterEventEmitter.emit(addEmittedData);
            this.dialogeRef.close();

          } else {
            console.log(data.part_no);
            const uniquePartNo = getUniqueByKey(data.part_no, 'part_no');
            console.log("Checking.... " + uniquePartNo.join(' , '), uniquePartNo);
            this.errorMessage = "Parts numbers " + uniquePartNo.join(' , ') + " are already parts of " + addEmittedData.policyName + " inventory policy  with effective from .." + addEmittedData.effectiveFrom;//+ "and" + addEmittedData.effectiveTo;           
          }



          // if (res.body && res.body.data) {
          //   this.resData.push(...res.body.data);
          //   console.log("Data fetch.." + this.resData);
          // }
        }
      });
    } else {
      this.addMasterEventEmitter.emit(addEmittedData);
      this.dialogeRef.close();
    }
  }

  editRowMasterDetails() {
    if (this.addOrEditform.invalid) {
      this.submitted = true;
      return;
    }

    const editEmittedData = {
      dock: this.addOrEdit.dock.value.dock,
      policyName: this.addOrEdit.policyName.value,
      description: this.addOrEdit.description.value,
      part_no: this.addOrEdit.part_no.value === null ? '' : this.addOrEdit.part_no.value,
      minDays: this.addOrEdit.minDays.value,
      maxDays: this.addOrEdit.maxDays.value,
      effectiveTo: this.addOrEdit.effectiveTo.value,
      effectiveFrom: this.addOrEdit.effectiveFrom.value,
      minBoxes: this.addOrEdit.minBoxes.value,
      maxBoxes: this.addOrEdit.maxBoxes.value,

    };
    //** */ call api to check validation effective from dataes have add parts number present in db or not if not it will alow
    // const offset = 0;
    // let selectedPartNo;
    // if (editEmittedData.part_no.length > 0) {
    //   selectedPartNo = [];
    //   editEmittedData.part_no.forEach((element) => {
    //     selectedPartNo.push(element.item_id);
    //   });
    // }
    // const data = {
    //   offset: offset,
    //   limit: 12000,
    //   business_entity: this.namcValue,
    //   workid: this.user,
    //   part_no: selectedPartNo,
    //   dock: [editEmittedData.dock],
    //   eff_from: editEmittedData.effectiveFrom === null ? '' : editEmittedData.effectiveFrom ,
    //   eff_to: editEmittedData.effectiveTo === null ? '' : editEmittedData.effectiveTo,
    //   user_role: this.userRole,
    // };
    // this.OspService.getpartInventoryGrid(data).subscribe({
    //   error: this.errorCallback,
    //   next: (res) => {

    //     if (res.body.data.length == 0) {
    //       this.errorMessage = null;
    //       console.log("Edited Data......" + editEmittedData);
    //       this.editMasterEventEmitter.emit(editEmittedData);
    //       this.dialogeRef.close();
    //     } else {
    //       console.log(data.part_no);
    //       const uniquePartNo = getUniqueByKey(data.part_no, 'part_no');
    //       console.log("Checking.... " + uniquePartNo.join(' , '), uniquePartNo);
    //       this.errorMessage = "Parts numbers " + uniquePartNo.join(' , ') + " are already parts of " + editEmittedData.policyName + " inventory policy  with effective from .." + editEmittedData.effectiveFrom;        
    //     }
    //   }
    // });


    // saving data for edit
    console.log("Edited Data......" + editEmittedData);
    this.editMasterEventEmitter.emit(editEmittedData);
    this.dialogeRef.close();

  }

  callRespectiveFunction() {
    if (this.data?.mode === 'edit') {
      this.editRowMasterDetails();
    } else {
      console.log("add function is called");

      this.addMasterDetails();
    }
  }
  onKeyPress(event: KeyboardEvent) {
    const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];
    const isNumber = /^[0-9]$/.test(event.key);

    // Allow number keys, allowed keys, and prevent default for others
    if (!isNumber && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  errorCallback = (error) => {
    logErrors(error);
    this.warningMessage = 'Something went wrong! Please contact support team.';
    //this.rowData = [];
    //this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
  };

}

