<!-- <div class="popup-container">
    <h2 mat-dialog-title class="p-2 shadow-sm" style="font-size: 20px">Delete Confirmation</h2>
    <div mat-dialog-content>
        <p class="p-2">Are you sure you want to delete this Shift?</p>
    </div>
    
    <div mat-dialog-actions class="d-flex flex-row-reverse align-items-center">
    <button class="black-button mx-2" (click)="dialogData('data')">Yes</button>
    <div mat-dialog-close class="text-primary mx-4" style="cursor: pointer;"  (click)="dialogData('NoData')">No</div>
    </div>
    <br>
  </div> -->
  <div class="row" style="border-bottom: 2px solid #ccc; margin-bottom: 10px">
    <div class="col-sm-11">
      <h3 class="head"><b>CONFIRMATION</b></h3>
    </div>
    <div class="col-sm-1">
      <button type="button" class="btn btn-dark btn-circle btn-xl" [mat-dialog-close]="true" data-dismiss="modal"
        aria-label="Close">
        <span aria-hidden="true">×</span>
      </button>
    </div>
  </div>
  <div>
    <!-- <p>Are you sure you want to delete this Shift? </p> -->
    <!-- <p> {{mesaage}}  </p> -->
    <p class="p-2">Are you sure that you want to delete {{fileName}}?</p>

    <p></p>
  
  </div>
  <div class="text-right">
    <button class="review_btn" mat-button mat-dialog-close="save" cdkFocusInitial>
      CONFIRM
    </button>
    <button class="cancel_btn" mat-button mat-dialog-close="cancel">CANCEL</button>
  
  </div>