import { Component } from '@angular/core';

@Component({
  selector: 'app-actual-arrival-editable',
  template: `
    <input
      #input
      [(ngModel)]="value"
      class="grid-cell-edit-layout "
      (change)="textUpdated()"
      maxlength="5"
      (input)="restrictNegativeValues($event)"
    />
  `,
  styles: [
    `
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      .grid-cell-edit-layout {
        width: 123px;
        height: 26px;
        border: 1px solid #d1d1d1;
      }
      .invalid-text {
        border: 2px solid red !important;
      }
    `,
  ],
})
export class CaseNoEditableComponent {
  public params: any;
  showInput = true;
  value: any;
  isEmpty = true;

  constructor() {}

  agInit(params: any): void {
    params.data.casenoEditable = this;
    console.log(params.data, 'from case');
    if (params.column === 'case_no') {
      console.log(params.data);
      this.value = params.data.case_no;
      console.log(this.value, 'caseNO');
      // if (this.value > 0) {
      //   this.isEmpty = false;
      // } else {
      //   this.isEmpty = true;
      // }
    }

    this.params = params;
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  public textUpdated() {
    if (this.params.column === 'case_no') {
      console.log(this.params.data, 'text');
      if (this.value !== this.params.data.case_no) {
        console.log(this.params.data, 'text');
        this.params.data.case_no = this.value;
        this.params.data.isUpdated = true;
        //this.params.data.isVanningVolUpdated = true;
      }
    }
  }

  restrictNegativeValues(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
  
    // Remove any negative sign (-)
    if (inputElement.value.includes('-')) {
      inputElement.value = inputElement.value.replace(/-/g, '');
    }
  
    // Update the ngModel value manually
    this.value = inputElement.value;
  }
}  
