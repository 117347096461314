import { Component } from '@angular/core';

@Component({
  selector: 'production-progress-text-editor',
  template: `
    <label *ngIf="showInput">{{ value }} </label>
    <input
      *ngIf="!showInput"
      #input
      type="number"
      (keypress)="onKeyPress($event)"
      maxlength="4"
      pattern="[0-9]"
      [(ngModel)]="value"
      class="grid-cell-edit-layout "
      (change)="textUpdated()"
    />
  `,
  styles: [
    `
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      .invalid-text {
        border: 2px solid red !important;
      }
    `,
  ],
})
export class ProductionProgressEditorComponent {
  public params: any;
  showInput = true;
  value: any;
  isEmpty = true;

  constructor() { }

  agInit(params: any): void {

    console.log("inside review chnages--------------", params.column)
    if (params.column === 'int_plan_volume') {
      params.data.intPlanVol = this;

      this.value = params.data.int_plan_volume;


      if (this.value) {
        this.isEmpty = false;
      } else {
        this.isEmpty = true;
      }
    }
    if (params.column === 'final') {
      params.data.Final = this;

      this.value = params.data.final;


      if (this.value) {
        this.isEmpty = false;
      } else {
        this.isEmpty = true;
      }
    }

    if (params.column === 'cumul_plan_net') {
      params.data.cumulPlanNet = this;
      this.value = params.data.cumul_plan_net;

      this.isEmpty = false;
    } else {
      this.isEmpty = true;
    }




    this.params = params;
    if (
      this.params.data.checkedEditMode


    ) {
      this.showInput = false;
    } else {
      this.showInput = true;
    }
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  public textUpdated() {
    console.log("textupadted")
    console.log(" outside inside if", this.params)

    if (this.params.column === 'cumul_plan_net') {
      if (this.value !== this.params.data.cumul_plan_net) {
        console.log(" outside inside if", this.params)

        this.params.data.cumul_plan_net = this.value;
        this.params.data.cumulPlanNet = true;
      }
    } else {
      this.params.data.cumulPlanNet = this.value;
    }

    // internal plan volume update
    if (this.params.column === 'int_plan_volume') {
      if (this.value !== this.params.data.int_plan_volume) {
        console.log(" outside inside if", this.params)

        this.params.data.int_plan_volume = this.value;
        this.params.data.intPlanVol = true;
      }
    } else {
      this.params.data.intPlanVol = this.value;
    }

    // internal plan volume update
    if (this.params.column === 'final') {
      if (this.value !== this.params.data.final) {
        console.log(" outside inside if", this.params)

        this.params.data.final = this.value;
        this.params.data.Final = true;
      }
    } else {
      this.params.data.Final = this.value;
    }






  }

  onKeyPress(event) {
    if (event.target.value.length <= 4) {
      return true;
    } else {
      return false;
    }
  }
  stopTyping() {
    return false;
  }

}
