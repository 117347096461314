import { Injectable } from '@angular/core';
import { EnvironmentConfiguration } from 'src/app/environment/services/environment.configuration';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProdprogressService {
  getHttpCustomOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: localStorage.getItem('Token'),
        'X-Api-Key': this.environment.rundownKey,
      }),
    };
  }

  prodProgressApiUrl = '';

  constructor(
    private http: HttpClient,
    private environment: EnvironmentConfiguration
  ) {
    this.prodProgressApiUrl = environment.prodProgressUrl;
  }


  getLine(data): Observable<any> {
    return this.http.post<any>(
      `${this.prodProgressApiUrl}/getLine`,
      data,
      this.getHttpCustomOptions()
    );
  }
  getModuletype(data): Observable<any> {
    return this.http.post<any>(
      `${this.prodProgressApiUrl}/getModuletype`,
      data,
      this.getHttpCustomOptions()
    );
  }
  getProdProgressData(data): Observable<any> {
    return this.http.post<any>(
      `${this.prodProgressApiUrl}/getProgressData`,
      data,
      this.getHttpCustomOptions()
    );
    }

}
