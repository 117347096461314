import { Component,  Inject, OnInit,  ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';

import {  dateFilterComparator } from '../../../../constants'
import * as moment from 'moment';


@Component({
  selector: 'app-review-shifts.component',
  templateUrl: './review-shifts.component.html',
  styleUrls: ['./review-shifts.component.scss']
})
export class ReviewShiftsComponent {
  rowData: any;
  @ViewChild('osPartsGrid') osPartsGrid;
  columnDefs;
  defaultColDef;
  rowSelection;
  gridOptions = {} as any;
  //gridOptions!: GridOptions;
  paginationPageSize;
  paginationNumberFormatter;
  pagination = true;
  context: any;
  editType;
  suppressClickEdit = true;

  editEnabled: boolean = false;


  editform: UntypedFormGroup = this.formBuilder.group({
    adjCode: [[], Validators.required],
    adjReason: [''],
  });
  editFormcontrol = this.editform.controls;

  constructor(
    // @Inject(MAT_DIALOG_DATA) public data,
    @Inject(MAT_DIALOG_DATA ) public data,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly dialogRef: MatDialogRef<ReviewShiftsComponent>

  ) {
    this.columnDefs = [

      { headerName: 'Shift No', field: 'shift_no',width: 150},
      { headerName: 'Day',field: 'day_of_week', width: 150,
      valueGetter: (params) => {
        switch (params.data.day_of_week) {
          case "1": return 'Sun';
          case "2": return 'Mon';
          case "3": return 'Tue';
          case "4": return 'Wed';
          case "5": return 'Thu';
          case "6": return 'Fri';
          case "7": return 'Sat';
          default: return '';  // Return empty string for any invalid value
        }

      }
    },
      { headerName: 'Start Time',field: 'startTime', width: 150,
      valueGetter: (params) => {
        if (params.data.startTime) {
          if (moment(params.data.startTime, 'HH:mm:ss', true).isValid()) {
            console.error('Invalid startTime format:', params.data.startTime);
            return params.data.startTime; // Return empty if invalid format
          }
          // return moment(params.data.startTime).format('HH:mm:ss').substring(0, 10);
        }
        else {
          return params.data.start_time;
        }
      },
      },
      { headerName: 'End Time',field: 'endTime', width: 150,
      valueGetter: (params) => {
        if (params.data.endTime) {
          if (moment(params.data.endTime, 'HH:mm:ss', true).isValid()) {
            console.error('Invalid endTime format:', params.data.endTime);
            return params.data.endTime; // Return empty if invalid format
          }
          // return moment(params.data.endTime).format('HH:mm:ss').substring(0, 10);
        }
        else {
          return params.data.end_time;
        }
      },
      },
      { headerName: 'Duration',field: 'day_offset', width: 150},
      {
        headerName: 'From',
        field: 'fromDate',
        width: 150,
        filter: 'agDateColumnFilter',
        valueGetter: (params) => {
          if (params.data.fromDate?.value) {
            return moment(params.data.fromDate?.value).format('MM/DD/YYYY').substring(0, 10);
          }
          else {
            return params.data.from_date;
          }
        },
        filterParams: {
          comparator: dateFilterComparator,
        },
        floatingFilter: true,

        comparator: this.dateComparator,


      },
      {
        headerName: 'To',
        field: 'toDate',
        width: 150,
        filter: 'agDateColumnFilter',
        valueGetter: (params) => {
          if (params.data.toDate?.value) {
            return moment(params.data.toDate?.value).format('MM/DD/YYYY').substring(0, 10);
          }
          else {
            return params.data.to_date;
          }
        },
        filterParams: {
          comparator: dateFilterComparator,
        },
        floatingFilter: true,

        comparator: this.dateComparator,


      },
      { headerName: 'Force',field: 'force_flag', width: 150},
      {
        headerName: 'Error',
        field: 'error',
        flex: 1,
        headerComponentParams: {
          template:
            '<span class="ag-header-cell-text" style="color:#D20000">Error</span>',
        },
        cellStyle: {
          color: '#D20000',
        },
        cellRenderer: (params) => {
          const html = params.value
            ?.split('\n')
            ?.map((line) => `<li style="list-style-type:none;">${line}</li>`)
            ?.join('\n');
          return `${html}`;
        },
      },
    ];
    this.rowSelection = 'multiple';
    this.editType = 'fullRow';

    this.rowData = data.modifiedRowData;
    this.getGridOptions();
  }

  getGridOptions() {
    this.gridOptions = {} as any;

    this.gridOptions.headerHeight = 38;

    this.gridOptions.rowHeight = 28;
    this.gridOptions.floatingFiltersHeight = 0;

    this.gridOptions.rowBuffer = 20;
    this.gridOptions.suppressMovableColumns = true;
    this.gridOptions.enableCellTextSelection = true;
    this.gridOptions.suppressContextMenu = false;
    this.gridOptions.suppressClipboardPaste = true;

    this.gridOptions.defaultColDef = {
      sortable: true,
      suppressMenu: true,
      filter: false,
      floatingFilter: false,

      wrapText: true,
      autoHeight: true,
      suppressPaste: false,

      floatingFilterComponentParams: { suppressFilterButton: true },
      unSortIcon: true,
      icons: {
        sortUnSort: '<img src="../../../assets/images/Rectangle8.svg">',
      },
      filterParams: {
        textCustomComparator(filter, value, filterText) {
          const filterTextLoweCase = filterText.toLowerCase();

          let valueLowerCase = '';
          if (value.params) {
            valueLowerCase = value.value.toString().toLowerCase();
          } else {
            valueLowerCase = value.toString().toLowerCase();
          }
          function contains(target, lookingFor) {
            if (target === null) {
              return false;
            }
            return target.indexOf(lookingFor) >= 0;
          }
          return contains(valueLowerCase, filterTextLoweCase);
        },

        // Debounce time - Delay before Floating filter search
        debounceMs: 1000,

        caseSensitive: true,
        suppressAndOrCondition: true,
        inRangeInclusive: true,
      },
    };

    return this.gridOptions;
  }

  dateComparator(date1, date2) {
    function monthToNum(date) {
      if (date === undefined || date === null) {
        return null;
      }

      const newDate: Date = new Date(date);

      const yearNumber = newDate.getFullYear();
      const monthNumber = newDate.getMonth();
      const dayNumber = newDate.getDate();
      const hoursNumber = newDate.getHours();
      const minutesNumber = newDate.getMinutes();

      const result = yearNumber * 10000 + monthNumber * 100 + dayNumber + hoursNumber * 60 + minutesNumber * 10;
      // 29/08/2004 => 20040829
      return result;
    }

    const date1Number = monthToNum(date1);
    const date2Number = monthToNum(date2);

    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }

    return date1Number - date2Number;
  }
  enableSave: boolean = false;

  ngOnInit() {
    this.enableSave=true;
    var errorCount = this.rowData.filter((x) => x.error !== '').length;

    this.enableSave = errorCount === 0;
  }


}
