import { Component, OnInit, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Constants } from '../../constants';
import { OSPStateService } from '../../services/osp-state.service';
import { MsalService } from '../../msal';
import { LookupService } from '../../services/lookup/lookup.service';
import { checkAccess, checkAccessPipe } from '../../constants';
import { logErrors } from 'src/app/shared/logger';

import { LicenseManager } from 'ag-grid-enterprise';
import { Subscription } from 'rxjs';
import { PipeLookupService } from 'src/app/services/pipe-lookup/pipe-lookup.service';
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  @ViewChild('toggleButton1') toggleButton1: ElementRef;
  @ViewChild('toggleButton2') toggleButton2: ElementRef;
  @ViewChild('toggleButton3') toggleButton3: ElementRef;
  @ViewChild('toggleButton4') toggleButton4: ElementRef;
  @ViewChild('toggleButton5') toggleButton5: ElementRef;
  @ViewChild('toggleArrow1') toggleArrow1: ElementRef;
  @ViewChild('toggleArrow2') toggleArrow2: ElementRef;
  @ViewChild('toggleArrow3') toggleArrow3: ElementRef;
  @ViewChild('toggleArrow4') toggleArrow4: ElementRef;
  @ViewChild('toggleArrow5') toggleArrow5: ElementRef;
  @ViewChild('menu') menu: ElementRef;
  activateHomeMenu = true;
  activatePartsOrdering = false;
  constants: Constants = new Constants();
  navlinks = this.constants.navLinkItems;
  ospipenavlinkitems = this.constants.osPipenavLinkItems
  osInventoryLinkItems = this.constants.osInventoryLinkItems;


  route: any;
  showPlantName = false;
  plantData: any = [];
  namcData: any = [];
  userName = localStorage.getItem('UserName');
  plantName: any;
  id: any;
  nvalue: any;
  workdayid = localStorage.getItem('workdayId');
  activatePipeLine = false;
  activateLlp = false;
  activateHelp = false;
  activatesetup = false;
  isMenuOpened: boolean = false;
  user_role = localStorage.getItem('UserRoles');
  isDisabled: boolean = false;
  roles: any;
  token: any;
  allowall: boolean = false;
  allowcompamy: boolean;
  company = localStorage.getItem('Company');

  userService: any;
  isOpen: boolean;
  navbarSubscription$: Subscription;
  navbarNamcSubscription$: Subscription;
  namcValue: string;
  userManual: any;

  constructor(
    location: Location,
    private readonly router: Router,
    private readonly state: OSPStateService,
    private readonly authService: MsalService,
    private readonly renderer: Renderer2,
    private readonly lookupService: LookupService,
    private readonly pipeLookupService: PipeLookupService,
  ) {
    this.renderer.listen('window', 'click', (e: Event) => {
      /**
       * Only run when toggleButton is not clicked
       * If we don't check this, all clicks (even on the toggle button) gets into this
       * section which in the result we might never see the menu open!
       * And the menu itself is checked here, and it's where we check just outside of
       * the menu and button the condition abbove must close the menu
       */
      if (
        e.target !== this.toggleButton1?.nativeElement &&
        e.target !== this.toggleArrow1?.nativeElement &&
        e.target !== this.toggleButton2?.nativeElement &&
        e.target !== this.toggleArrow2?.nativeElement &&
        e.target !== this.toggleButton3?.nativeElement &&
        e.target !== this.toggleArrow3?.nativeElement &&
        e.target !== this.toggleButton4?.nativeElement &&
        e.target !== this.toggleArrow4?.nativeElement &&
        e.target !== this.toggleButton5?.nativeElement &&
        e.target !== this.toggleArrow5?.nativeElement
      ) {
        this.isMenuOpen = false;
        this.id = false;
      }
    });

    this.router.events.subscribe((val) => {
      if (location.path() !== '') {
        this.route = location.path();

        localStorage.setItem('urlLocation', this.route);
      } else {
        this.route = '';
      }

      this.checkActiveRoute(this.route);
    });
  }

  isMenuOpen = false;

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  drop(param: any) {
    if (this.id === param) {
      this.id = '';
    } else {
      this.id = param;
    }
  }

  activationtab(status: any) {
    this.activateHomeMenu = false;
    this.activatePartsOrdering = false;
    this.activatePipeLine = false;
    this.activateLlp = false;
    this.activatesetup = false;
    this.activateHelp = false;

    if (status === '0') {
      this.activateHomeMenu = true;
      return;
    }
    if (status === '1') {
      this.activatePartsOrdering = true;
      return;
    }
    if (status === '2') {
      this.activatePipeLine = true;
      return;
    }
    if (status === '3') {
      this.activateLlp = true;
      return;
    }
    if (status === '4') {
      this.activatesetup = true;
      return;
    }
    if (status === '5') {
      this.activateHelp = true;
      return;
    }
  }

  handleChange(): void {
    this.id = false;
  }

  checkActiveRoute(path: any) {
    let callFrom = '';
    if (path === `/${this.navlinks.fod.route}`) {
      callFrom = 'home';
    }

    this.activateMenu(null, callFrom);
  }

  activateMenu(value: any, callFrom: any) {
    if (value) {
      localStorage.removeItem('landingUrl');
    }

    if (callFrom === 'logo' || callFrom === 'home') {
      this.activateHomeMenu = true;
      this.activatePartsOrdering = false;

      this.activatePipeLine = false;

      this.activateLlp = false;

      this.activatesetup = false;

      this.activateHelp = false;
    }
  }

  onNAMCChange(newValue: any) {
    for (const data of this.namcData) {
      if (this.plantName === data) {
        localStorage.setItem('namcSelected', JSON.stringify(data));
        localStorage.setItem('namcName', data.label);
        localStorage.setItem('namcvalue', data.value);
        localStorage.setItem('namcDestCode', data.dest_code);
        this.state.setNamc({
          namc: JSON.stringify(data),
          WorkdayID: '',
          Name: '',
          Role: '',
        });
      }
    }
  }

  test(param) {
    this.router.navigate(['/order']);
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.authService.logout();
  }

  checkPermission() {
    this.token = localStorage.getItem('Token');
  }

  setNamcData(namcList) {
    if (!this.namcData.length) {
      this.namcData = namcList.map((namc) => {
        return {
          label: namc.business_entity_name,
          value: namc.business_entity_code,
          dest_code: namc.dest_code,
          company_code: namc.company_code,
        };
      });
    }
  }
  access1;
  accessPipe;
  setNamcOnInit() {
   

    this.company = localStorage.getItem('Company');

    if (this.company === 'TNA') {
      this.roles = 'OSP.NAMCADMIN';
      this.company = 'TMP';
    }
    const navbarPermission = checkAccess();
    this.access1 = navbarPermission;

    const navbarPermissionPipe = checkAccessPipe();
    this.accessPipe = navbarPermissionPipe;



    if (this.roles?.includes('OSP.ADMIN') || this.roles?.includes('OSP.READONLY')
      || this.roles?.includes('OSPIPE.ADMIN') || this.roles?.includes('OSPIPE.READONLY')) {


      this.plantName = this.namcData[0];

      if (localStorage.getItem('namcSelected') !== undefined && localStorage.getItem('namcSelected')) {
        this.plantName = this.namcData.filter((namc) => {
          return namc.value === JSON.parse(localStorage.getItem('namcSelected')).value;
        })[0];
      }

      this.allowall = true;
    } else {
      const filterdNamc = this.namcData.filter((element) => {
        return element.company_code === this.company;
      });

      this.plantName = filterdNamc[0];
      this.allowall = false;
    }



  }

  ngOnInit() {
    if (this.router.url === '/dashboard') {
      this.activationtab('0');
    }
  

    this.navbarNamcSubscription$ = this.state.getNamc().subscribe((_) => this.setNamcOnInit());

    this.navbarSubscription$ = this.state.nameState.subscribe((data) => {
      console.log(data)
      this.state.roleState.subscribe((roleData) => {
       
        this.state.companyState.subscribe((companyData) => {
          if (!data || !roleData) {
            return;
          }

          if (!this.namcData.length) {
            this.lookupService.getNAMCList().subscribe((response) => {
              this.setNamcData(response.body.data);

              this.userName = data;

              this.roles = roleData;
             
              this.setNamcOnInit();

              if (this.plantName) {
                localStorage.setItem('namcSelected', JSON.stringify(this.plantName));
                localStorage.setItem('namcvalue', this.plantName.value);
                localStorage.setItem('namcName', this.plantName.label);
                localStorage.setItem('namcDestCode', this.plantName.dest_code);
                this.state.setNamc({
                  namc: JSON.stringify(this.plantName),
                  WorkdayID: '',
                  Name: '',
                  Role: '',
                });

                this.updateUserSession();
              }
            });

            this.lookupService.getLicenseKey().subscribe({
              next: (res) => {
                const { ospAGGridLicenseKey } = res.body.data;

                // logData(ospAGGridLicenseKey);
                LicenseManager.setLicenseKey(ospAGGridLicenseKey);

                this.state.setLicenseKey(true);
              },
              error: (e) => {
                logErrors(e);
              },
            });
          }
        });
      });
    });
  }

  ngOnDestroy() {
    this.navbarSubscription$.unsubscribe();
    this.navbarNamcSubscription$.unsubscribe();
  }

  updateUserSession() {
    this.lookupService.updateUserSession('login').subscribe((data) => {
      const currentTime = new Date();
      currentTime.setSeconds(currentTime.getSeconds() + 10);

      localStorage.setItem('userSessionCooldown', currentTime.toISOString());
    });
  }

  getUserManuals3Link() {

    this.namcValue = localStorage.getItem('namcvalue');

    if (localStorage.getItem('workdayId')) {
      this.workdayid = localStorage.getItem('workdayId');
    }
    if (localStorage.getItem('UserRoles')) {
      this.user_role = localStorage.getItem('UserRoles');
    }
    const data = {
      business_entity: this.namcValue,
      workid: this.workdayid,
      user_role: this.user_role,
    };

    this.pipeLookupService.getUserManuals3Link(data).subscribe((res) => {


      // this.userManual = res.body.userManuals3link.url
      this.userManual = res.body.userManuals3link.urls[1].url
      //window.open(this.userManual, "_blank")
      // const a = document.createElement('a');

      // a.href = this.userManual;
      // //console.log(a.href);
      // a.target = '_blank';
      // a.rel = 'noreferrer noopener';
      if (this.userManual) {
        saveAs(this.userManual);
      }
      // if (a) {
      //   window.location.href = a.href;
      // }

    })


  }


}
