import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { log } from 'console';


@Component({
  selector: 'app-shifts-edit-dialog',
  templateUrl: './shifts-edit-dialog.component.html',
  styleUrls: ['./shifts-edit-dialog.component.scss']
})
export class ShiftsEditDialogComponent implements OnInit{
    addShiftsEventEmitter = new EventEmitter();
    editMasterEventEmitter = new EventEmitter();
    master_id: any;
    master_name: any;
    key_item: any;
    data_item: any;
    business_entity: any;
    new_key_item: any;
    cstatus: any;
    showaddpopup: boolean = false;
    showeditpopup: boolean = false;
    dock: any;
    popupname: any;
    shiftNumber: any;
    dayWeek: any;
    startTime: any;
    endTime: any;
    inMasterCalendar: any;
    inNextDay: any;
    dataItem: any;
    enableError: boolean = false;
    userForm: UntypedFormGroup;
    shiftNumbers: any[] = [];
    dayWeeks:any[]=[];
    masterCalendar:any[]=[];
    nextDay:any[]=[];
    addOrEditform: UntypedFormGroup = this.formBuilder.group({
      dock: ['', Validators.required],
      shiftNumber: [''],
      dayWeek: [''],
      startTime:['',Validators.required],
      endTime:['',Validators.required],
      inMasterCalendar:[''],
      inNextDay:[''],
      effectiveTo:[null],
      effectiveFrom:[null,Validators.required], 
    },
    );
    constructor(
      @Inject(MAT_DIALOG_DATA) public data,
      private readonly dialogeRef: MatDialogRef<ShiftsEditDialogComponent>,
      private readonly formBuilder: UntypedFormBuilder
    ) {}
    get addOrEdit() {
      return this.addOrEditform.controls;
    }
    // Custom validator to check inNextDay if inMasterCalendar is entered
  // minMaxDaysValidator(formGroup: UntypedFormGroup): { [key: string]: boolean } | null {
  //   const inMasterCalendar = formGroup.get('inMasterCalendar')?.value;
  //   const inNextDay = formGroup.get('inNextDay')?.value;
  // console.log('entered')
  //   if (!inMasterCalendar && inNextDay) {
  //     // consol
  //     return { minDaysRequired: true };
  //   }

  //   return null;
  // }
  
    
    ngOnInit(): void {
      
      this.business_entity = localStorage.getItem('namcvalue');
      this.shiftNumbers = [
        { value: 1 },
        { value: 2 },
        { value: 3 }
      ];
      this.dayWeeks = [
        { label: 'sun', value: 1 },
        { label: 'mon', value: 2 },
        { label: 'tue', value: 3 },
        { label: 'wed', value: 4 },
        { label: 'thu', value: 5 },
        { label: 'fri', value: 6 },
        { label: 'sat', value: 7 },
      ];
      this.masterCalendar=[
        { value: 'N' },
        { value: 'Y' },
      ];
      this.nextDay=[
        { value: 'N' },
        { value: 'Y' },
      ];
      console.log("data---",this.data);
      
  
      if (this.data?.mode === 'edit') {
        if (this.data.data?.dock) {
          this.addOrEdit.dock.setValue(
            this.data.data.dock 
          );
          
        }
        // if (this.data.data?.shiftNumber) {
        //   this.addOrEdit.shiftNumber.setValue(
        //     this.data.data.shiftNumber 
        //   );
          
        // }
        // if (this.data?.policy_name) {
        //   this.addOrEdit.dayWeek.setValue(this.data?.policy_name);
        //   // this.addOrEdit.masterId.disable();
        //   this.addOrEditform.controls.dayWeek.setValue(this.data?.policy_name);
        // }
        // if (this.data?.mkey) {
        //   this.addOrEdit.keyItem.setValue(this.data?.mkey);
        // }
        // if (this.data?.mdata) {
        //   this.addOrEdit.dataItem.setValue(this.data?.mdata);
        // }
      }
    }
    // atLeastOnePhoneRequired(group : UntypedFormGroup) : {[s:string ]: boolean} {
    //   if (group) {
    //     if((group.controls['inMasterCalendar'].value) && (group.controls['inNextDay'].value) || 
    //     (group.controls['minBoxes'].value) && (group.controls['maxBoxes'].value)) {
    //       return null;
    //     }
    //   }
    //   // return { group.controls.inMasterCalendar.errors : true};
    // }
   
    submitted: boolean = false;
    convertDateFormat(date: string): string {
      const dateObj = new Date(date);
      const month = dateObj.getMonth() + 1;  // Months are 0-indexed
      const day = dateObj.getDate();
      const year = dateObj.getFullYear();
    
      // Return the date in mm/dd/yyyy format
      return `${month < 10 ? '0' + month : month}/${day < 10 ? '0' + day : day}/${year}`;
    }
    convertToFullTimeFormat(time: string): string {
      // Check if the time already includes seconds, if not, add ':00'
      if (time.length === 5) { // The format HH:mm (length 5)
        return `${time}:00`; // Append ':00' to represent seconds
      }
      return time; // If it already includes seconds, return as is
    }
    addMasterDetails() {
      const formattedEffectiveFrom = this.convertDateFormat(this.addOrEdit.effectiveFrom.value) || '';
      const formattedEffectiveTo = this.convertDateFormat(this.addOrEdit.effectiveTo.value) || '';
      // this.addOrEditform.get('effectiveFrom').patchValue(formattedEffectiveFrom);
      if (!this.addOrEditform.valid) {
        this.submitted = true;
        return;
      }
      
      const formattedStartTime = this.convertToFullTimeFormat(this.addOrEdit.startTime.value);
      const formattedEndTime = this.convertToFullTimeFormat(this.addOrEdit.endTime.value);
      // Prepare the emitted data from the form controls
      const addEmittedData = {
        dock: this.addOrEdit.dock.value.dock,
        shiftNumber: this.addOrEdit.shiftNumber.value,
        dayWeek: this.addOrEdit.dayWeek.value,
        // startTime: this.addOrEdit.startTime.value,
        startTime: formattedStartTime,
        endTime: formattedEndTime,
        inNextDay: this.addOrEdit.inNextDay.value,
        inMasterCalendar: this.addOrEdit.inMasterCalendar.value,
        effectiveTo: this.addOrEdit.effectiveTo.value,
        effectiveFrom: this.addOrEdit.effectiveFrom.value,
      };
    
      this.addShiftsEventEmitter.emit(addEmittedData);
      this.dialogeRef.close();
    }

    editRowMasterDetails() {
      if (this.addOrEditform.invalid) {
        this.submitted = true;
        return;
      }
  
      const editEmittedData = {
        master_id: this.addOrEdit.masterId.value.master_id,
        master_name: this.addOrEdit.masterName.value.name,
        startTime: this.addOrEdit.startTime.value,
        data_item: this.addOrEdit.dataItem.value,
      };
      this.editMasterEventEmitter.emit(editEmittedData);
      this.dialogeRef.close();
      
    }
  
    callRespectiveFunction() {
      if (this.data?.mode === 'edit') {
        this.editRowMasterDetails();
      } else {
        console.log("add function is called");
        
        this.addMasterDetails();
      }
    }
    onKeyPress(event: KeyboardEvent) {
      const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];
      const isNumber = /^[0-9]$/.test(event.key);
  
      // Allow number keys, allowed keys, and prevent default for others
      if (!isNumber && !allowedKeys.includes(event.key)) {
        event.preventDefault();
      }
    }
  }
  
