import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentConfiguration } from 'src/app/environment/services/environment.configuration';

@Injectable({
  providedIn: 'root',
})
export class LookupService {
  lookupUrl = '';

  constructor(private http: HttpClient, private environment: EnvironmentConfiguration) {
    this.lookupUrl = environment.lookupUrl;
  }

  getToken = () => localStorage.getItem('Token');

  getBusinessEntity = () => localStorage.getItem('namcvalue');

  getBusinessEntityName = () => localStorage.getItem('namcName');

  getUserRole = () => localStorage.getItem('UserRoles');

  getUserUPN = () => localStorage.getItem('UPN');

  getWorkID = () => localStorage.getItem('workdayId');

  getUserName = () => localStorage.getItem('UserName');

  getApiHeaders = () => {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: this.getToken(),
      'X-Api-Key': this.environment.lookupKey,
    });
  };

  httpCustomOptions = () => {
    return {
      params: {
        business_entity: this.getBusinessEntity(),
        user_role: this.getUserRole(),
        workid: this.getWorkID(),
      },
      headers: this.getApiHeaders(),
    };
  };

  getLicenseKey(): Observable<any> {
    return this.http.get<any>(`${this.lookupUrl}/license`, this.httpCustomOptions());
  }

  getKanban(): Observable<any> {
    return this.http.get<any>(`${this.lookupUrl}/kanban`, this.httpCustomOptions());
  }

  getEasternDate(): Observable<any> {
    return this.http.get<any>(`${this.lookupUrl}/getEasternDate`, this.httpCustomOptions());
  }

  getContainer(): Observable<any> {
    return this.http.get<any>(`${this.lookupUrl}/container`, this.httpCustomOptions());
  }

  getPartNumber(): Observable<any> {
    return this.http.get<any>(`${this.lookupUrl}/partnumber`, this.httpCustomOptions());
  }
  getDestCode(): Observable<any> {
    return this.http.get<any>(`${this.lookupUrl}/destcode`, this.httpCustomOptions());
  }
  getSupplier(): Observable<any> {
    return this.http.get<any>(`${this.lookupUrl}/supplier`, this.httpCustomOptions());
  }
  getDock(): Observable<any> {
    return this.http.get<any>(`${this.lookupUrl}/dock`, this.httpCustomOptions());
  }
  getSpecialist(): Observable<any> {
    return this.http.get<any>(`${this.lookupUrl}/specialist`, this.httpCustomOptions());
  }
  getAdjtype(): Observable<any> {
    return this.http.get<any>(`${this.lookupUrl}/adjtype`, this.httpCustomOptions());
  }
  getNotification(): Observable<any> {
    return this.http.get<any>(`${this.lookupUrl}/notification`, this.httpCustomOptions());
  }
  getNAMCList(): Observable<any> {
    return this.http.get<any>(`${this.lookupUrl}/namclist`, this.httpCustomOptions());
  }
  getContact(data): Observable<any> {
    return this.http.get<any>(`${this.lookupUrl}/getEmailId`, this.httpCustomOptions());
  }
  getBatchStatus(data): Observable<any> {
    return this.http.get<any>(`${this.lookupUrl}/getbatchstatus`, this.httpCustomOptions());
  }
  getVideos(data): Observable<any> {
    return this.http.post<any>(`${this.lookupUrl}/downloaTrainingvideos`, data, this.httpCustomOptions());
  }

  getReleaseNotes(data): Observable<any> {
    return this.http.post<any>(`${this.lookupUrl}/downloadReleaseNotes`, data, this.httpCustomOptions());
  }

  updateUserSession(action): Observable<any> {
    const data = {
      business_entity: this.getBusinessEntity(),
      business_entity_name: this.getBusinessEntityName(),
      userid: this.getUserUPN(),
      workid: this.getWorkID(),
      user_role: this.getUserRole(),
      action,
    };

    return this.http.post<any>(`${this.lookupUrl}/userSession`, data, this.httpCustomOptions());
  }

  getUserShowHideColumns(): Observable<any> {
    const data = {
      business_entity: this.getBusinessEntity(),
      business_entity_name: this.getBusinessEntityName(),
      userid: this.getUserName(),
      workid: this.getWorkID(),
      user_role: this.getUserRole(),
    };

    return this.http.post<any>(`${this.lookupUrl}/getShowHideColumns`, data, this.httpCustomOptions());
  }

  updateUserShowHideColumns(columns): Observable<any> {
    const data = {
      business_entity: this.getBusinessEntity(),
      business_entity_name: this.getBusinessEntityName(),
      userid: this.getUserName(),
      workid: this.getWorkID(),
      user_role: this.getUserRole(),
      ...columns,
    };

    return this.http.post<any>(`${this.lookupUrl}/updateShowHideColumns`, data, this.httpCustomOptions());
  }
  getqpcforpart(): Observable<any> {
    const data = {
      business_entity: this.getBusinessEntity(),
      business_entity_name: this.getBusinessEntityName(),
      userid: this.getUserName(),
      workid: this.getWorkID(),
      user_role: this.getUserRole(),
    };

    return this.http.post<any>(`${this.lookupUrl}/getqpcforpart`, data, this.httpCustomOptions());
  }
}
