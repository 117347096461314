import { Component } from '@angular/core';

@Component({
  selector: 'app-actual-arrival-editable',
  template: `
    <input
      #input
      type="number"
     
      [(ngModel)]="value"
      class="grid-cell-edit-layout "
      (change)="textUpdated()"
      (keydown)="onKeyPress($event)"
    />
  `,
  styles: [
    `
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      .grid-cell-edit-layout {
        width: 123px;
        height: 26px;
        border: 1px solid #d1d1d1;
      }
      .invalid-text {
        border: 2px solid red !important;
      }
    `,
  ],
})
export class NoOfPiecesEditableComponent {
  public params: any;
  showInput = true;
  value: any;
  isEmpty = true;

  lastDayOfVanMonth = localStorage.getItem('lastDayOfVanMonth');

  constructor() {}

  agInit(params: any): void {
    params.data.noOfPieces = this;
    if (params.column === 'number_of_pieces') {
      this.value = params.data.number_of_pieces;
      // if (this.value > 0) {
      //   this.isEmpty = false;
      // } else {
      //   this.isEmpty = true;
      // }
    }

    this.params = params;
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  public textUpdated() {
    if (this.params.column === 'number_of_pieces') {
      if (this.value !== this.params.data.number_of_pieces) {
        this.params.data.number_of_pieces = this.value;

        this.params.data.isUpdated = true;
      }
    }
  }

  // onKeyPress(event) {
  //   if (event.target.value.length <= 4) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  onKeyPress(event: KeyboardEvent) {
    console.log('srilekha.......');
    const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];
    const isNumber = /^[0-9]$/.test(event.key);

    // Access the input element
    const inputElement = event.target as HTMLInputElement;

    // Check if the current value plus the new key would exceed the max length
    const currentValue = inputElement.value;
    if (currentValue.length >= 5 && isNumber) {
      event.preventDefault(); // Prevent input if max length is reached
      return;
    }

    // Allow number keys, allowed keys, and prevent default for others
    if (!isNumber && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }
}
