import { Component } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'grid-time-editor',
  template: `
    <label *ngIf="showInput">{{ value }} </label>
    <input
      *ngIf="!showInput"
      #input
      style="width:93px"
      type="time"
      step="2"
      [(ngModel)]="value"
      [max]="disableDate"
      (keydown)="stopTyping()"
      class="grid-cell-edit-layout "
      (change)="textUpdated()"

    />
  `,
  styles: [
    `
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    `,
  ],
})
// (keydown.enter)="onEnter()"
// (keydown.escape)="onEscape()"
export class GridBreaksTimeEditorComponent {
  public params: any;
  showInput = true;
  value: any;
  isEmpty = true;
  disableDate = '';
  reportdate = new Date();
  private eGui: HTMLElement;

  constructor() {
    // this.eGui = document.createElement('span'); // Create a span element to render the time
  }

  // ag-Init method, called when the editor is initialized
  agInit(params: any): void {
    this.params = params;

    // Handle start_time (assuming it's in HH:MM:SS format, like '12:09:45')
    if (params.column === 'breakFrom') {
      this.value = params.data.time_from; // Get the time from the data
      // this.disableDate = moment().format('YYYY-MM-DD'); // For disabling date (current date)
      
      if (this.value) {
        this.isEmpty = false;
      } else {
        this.isEmpty = true;
      }

      // If value is a valid time string, format it
      if (this.value && moment(this.value, 'HH:mm:ss', true).isValid()) {
        // Set the formatted time value (e.g., '12:09:45' stays as is)
        this.value = moment(this.value, 'HH:mm:ss').format('HH:mm:ss');
      } else {
        this.value = '00:00:00'; // Fallback if the time is invalid
      }
    }
    if (params.column === 'timeTo') {
      this.value = params.data.time_to; // Get the time from the data
      this.disableDate = moment().format('YYYY-MM-DD'); // For disabling date (current date)
      
      if (this.value) {
        this.isEmpty = false;
      } else {
        this.isEmpty = true;
      }

      // If value is a valid time string, format it
      if (this.value && moment(this.value, 'HH:mm:ss', true).isValid()) {
        // Set the formatted time value (e.g., '12:09:45' stays as is)
        this.value = moment(this.value, 'HH:mm:ss').format('HH:mm:ss');
      } else {
        this.value = '00:00:00'; // Fallback if the time is invalid
      }
    }
    
    

    // Toggle showInput based on whether the field is in checkedEditMode
    if (params.data.checkedEditMode) {
      this.showInput = false;
      this.value = moment(this.value, 'HH:mm:ss').format('HH:mm:ss');;
    } else {
      this.showInput = true;
      if (this.value) {
        this.value = moment(this.value, 'HH:mm:ss').format('HH:mm:ss');;
      }
    }
  }

  // Refresh the editor if needed (e.g., reloading data)
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  // Called when the time input is updated
  public textUpdated() {
    // Handle updates to the toDate or fromDate fields
    if (this.params.column === 'breakFrom') {
      if (this.value !== this.params.data.breakFrom) {
        this.params.data.breakFrom = this.value;
        this.params.data.isToDate = true;
      }
    } else {
      this.params.data.time_from = this.value;
    }

    if (this.params.column === 'timeTo') {
      if (this.value !== this.params.data.timeTo) {
        this.params.data.timeTo = this.value;
        this.params.data.isFromDate = true;
      }
    } else {
      this.params.data.end_time = this.value;
    }
  }

  // Restrict input to a certain number of characters (optional)
  onKeyPress(event) {
    if (event.target.value.length <= 4) {
      return true;
    } else {
      return false;
    }
  }

  // Prevent the user from typing (e.g., for time input)
  stopTyping() {
    return false;
  }
  onEnter():void{
    this.params.stopEditing()
  }
  onEscape():void{
    this.params.stopEditing(true)
  }
  getValue():any{
    return false
  }
  isPopup():boolean{
    return false
  }
  
}
