import { Component } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'grid-text-editor',
  template: `
    <label *ngIf="showInput">{{ value }} </label>
    <input
      *ngIf="!showInput"
      #input
      style="width:93px"
      type="time"
      step="2"
      [(ngModel)]="value"
      [max]="disableDate"
      (keydown)="stopTyping()"
      class="grid-cell-edit-layout "
      (change)="textUpdated()"
    />
  `,
  styles: [
    `
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    `,
  ],
})
export class GridShiftsTimeEditorComponent {
  public params: any;
  showInput = true;
  value: any;
  isEmpty = true;
  disableDate = '';
  reportdate = new Date();
  private eGui: HTMLElement;

  constructor() {
    // this.eGui = document.createElement('span'); // Create a span element to render the time
  }

  // ag-Init method, called when the editor is initialized
  agInit(params: any): void {
    this.params = params;

    // Handle start_time (assuming it's in HH:MM:SS format, like '12:09:45')
    if (params.column === 'startTime') {
      this.value = params.data.start_time; // Get the time from the data
      this.disableDate = moment().format('HH:mm:ss'); // For disabling date (current date)
      
      if (this.value) {
        this.isEmpty = false;
      } else {
        this.isEmpty = true;
      }

      // If value is a valid time string, format it
      if (this.value && moment(this.value, 'HH:mm:ss', true).isValid()) {
        // Set the formatted time value (e.g., '12:09:45' stays as is)
        this.value = moment(this.value, 'HH:mm:ss').format('HH:mm:ss');
      } else {
        this.value = '00:00:00'; // Fallback if the time is invalid
      }
    }
    if (params.column === 'endTime') {
      this.value = params.data.end_time; // Get the time from the data
      this.disableDate = moment().format('HH:mm:ss') // For disabling date (current date)
      
      if (this.value) {
        this.isEmpty = false;
      } else {
        this.isEmpty = true;
      }

      // If value is a valid time string, format it
      if (this.value && moment(this.value, 'HH:mm:ss', true).isValid()) {
        // Set the formatted time value (e.g., '12:09:45' stays as is)
        this.value = moment(this.value, 'HH:mm:ss').format('HH:mm:ss');
      } else {
        this.value = '00:00:00'; // Fallback if the time is invalid
      }
    }
    
    

    // Toggle showInput based on whether the field is in checkedEditMode
    if (params.data.checkedEditMode) {
      this.showInput = false;
      this.value = moment(this.value, 'HH:mm:ss').format('HH:mm:ss');
    } else {
      this.showInput = true;
      if (this.value) {
        this.value = moment(this.value, 'HH:mm:ss').format('HH:mm:ss');
      }
    }
  }

  // Refresh the editor if needed (e.g., reloading data)
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  // Called when the time input is updated
  public textUpdated() {
    // Handle updates to the toDate or fromDate fields
    if (this.params.column === 'startTime') {
      if (this.value !== this.params.data.startTime) {
        this.params.data.startTime = this.value;
        this.params.data.isStartTime = true;
      }
    } else {
      this.params.data.start_time = this.value;
    }

    if (this.params.column === 'endTime') {
      if (this.value !== this.params.data.endTime) {
        this.params.data.endTime = this.value;
        this.params.data.isEndTime = true;
      }
    } else {
      this.params.data.end_time = this.value;
    }
  }

  // Restrict input to a certain number of characters (optional)
  onKeyPress(event) {
    if (event.target.value.length <= 4) {
      return true;
    } else {
      return false;
    }
  }

  // Prevent the user from typing (e.g., for time input)
  stopTyping() {
    return false;
  }
}
