import { Component, ElementRef, ViewChild } from '@angular/core';
import * as mm from 'moment-timezone';

@Component({
  selector: 'app-actual-arrival-editable',
  template: `
    <input
      #input
      [(ngModel)]="value"
      type="date"
      [min]="minDate"
      class="grid-cell-edit-layout "
      (change)="textUpdated()"
    />
  `,
  styles: [
    `
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      .grid-cell-edit-layout {
        width: 123px;
        height: 26px;
        border: 1px solid #d1d1d1;
      }
      .invalid-text {
        border: 2px solid red !important;
      }
    `,
  ],
})
export class ActualArrivalEditableComponent {
  public params: any;
  showInput = true;
  value: any;
  isEmpty = true;
  minDate: String;
  easternCurrentDate: string;

  constructor() {}

  // agInit(params: any): void {
  //   console.log(params, 'params...');

  //   params.data.arrivalDate = this;
  //   console.log(params.data.arrivalDate, 'hi');
  //   if (params.column === 'arriving_date') {
  //     console.log('column....');
  //     this.value = params.data.arriving_date;
  //     this.value = moment(this.value).format('YYYY-MM-DD');
  //     console.log(this.value, 'value......');
  //   }

  //   this.params = params;
  //   console.log(this.params, 'params......');
  // }

  agInit(params: any): void {
    // Set the minimum date to today in the format YYYY-MM-DD
    this.easternCurrentDate = String(mm().tz('US/Michigan').format('YYYY-MM-DD')).substring(0, 10);

    this.minDate = new Date().toISOString().split('T')[0];

    params.data.arrivalDate = this;
    console.log(params.data.arrivalDate, '');
    if (params.column === 'arriving_date') {
      this.value = params.data.arriving_date;
      console.log(this.value, 'value......');
    }

    this.params = params;
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  public textUpdated() {
    console.log(this.params.data, 'ui');
    if (this.params.column === 'arriving_date') {
      if (this.value !== this.params.data.arriving_date) {
        this.params.data.arriving_date = this.value;
        this.params.data.isUpdated = true;
      }
    }
  }

  onKeyPress(event) {
    if (event.target.value.length <= 4) {
      return true;
    } else {
      return false;
    }
  }
}
