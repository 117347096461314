import { Component, Input } from '@angular/core';
import * as mm from 'moment-timezone';

@Component({
  selector: 'app-airfreight-arrived-checkbox',
  template: `
  <mat-checkbox
  
    (change)="checkCheckBoxvalue($event)"
    [(ngModel)]="value"
  ></mat-checkbox>
   
  `,
  styles: [
    `
      ::ng-deep .mat-checkbox .mat-checkbox-frame {
        border: 1px solid #c5c5c5 !important;
      }
      ::ng-deep .mat-checkbox-disabled .mat-checkbox-background {
        background-color: #d3d3d3;
      }
    `,
  ],
})
// <label *ngIf="showInput" style="color:#2688DA"> &#10004; </label>
//   <mat-checkbox
//     [disabled]="disable"
//     *ngIf="showCheckBox"
//     (change)="checkCheckBoxvalue($event)"
//     [(ngModel)]="value"
//   ></mat-checkbox>
export class AirfreightArrivedCheckboxComponent {

  @Input() cell: any;

  showInput: boolean;
  value = '';
  isEmpty = true;
  showCheckBox = false;
  editable: boolean;
  disable: boolean = false;

  public params: any;
  agInit(params: any): void {

    console.log(params.data,"is arrived called--------");
    if (params.column === 'isArrived') {
      console.log("isArrived called");
      
      params.data.ArrivedChecked = this;
      this.value = params.data.isArrived;
      // this.disable = params.data.disabled.supplieredit;
    }

    if (this.value) {
      this.showInput = true;
    } else {
      this.showInput = false;
    }

    this.params = params;

    if (this.params.data.checkedEditMode) {
      this.showCheckBox = true;
      this.showInput = false;
    } else {
      this.showCheckBox = false;
    }

    if (
      this.params.data.checkedEditMode 
      && 
      new Date(this.params.data.date) <=
        new Date(String(mm().tz('US/Michigan').format('MM/DD/YYYY')).substring(0, 10))
    ) {
      
      this.disable = true;
    }
  }

  constructor() {}

  checkCheckBoxvalue(event) {
    if (this.params.column === 'isArrived') {
      this.params.data.isArrived = this.value;
    }
    

    
  }
}
