import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
// import { ExportStateService } from 'src/app/services/export-state.service'

@Component({
  selector: 'app-edit-breaks',
  template: `
  <div class="popup-container">
  <div class="popup-content">
    <h3>Edit Row</h3>
    <div>
      <label for="name">Break From</label>
      <input type="time" id="time_from" [(ngModel)]="data.time_from" name="time_from" />

      <label for="email">To Break</label>
      <input type="time" id="end_time" [(ngModel)]="data.end_time" name="end_time" />

      <div class="popup-actions">
        <button type="button"  class="cancel_btn" (click)="onCancel()">Cancel</button>
        <button type="button" class="review_btn" (click)="onSave()">Review Changes</button>
      </div>
    </div>
  </div>
</div>
`,
styles: [
`
  .popup-container {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 10;
    width: 400px;
    height : 150px;
  }

  .popup-actions {
    display: flex;
    justify-content: space-between;
  }
`
]
})
export class EditBreaksComponent implements OnInit {
  param: any
  fileName: any
  mesaage: string;

  // constructor(
    constructor(
      public dialogRef: MatDialogRef<EditBreaksComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any // This will receive the row data
    ) {}
  ngOnInit(): void {
    // throw new Error('Method not implemented.');
  }
  
    onSave(): void {
      this.dialogRef.close(this.data); // Pass the updated data back to the caller
    }
  
    onCancel(): void {
      this.dialogRef.close(); // Close the dialog without making any changes
    }
}