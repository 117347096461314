import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  
  private orderDataSubject = new BehaviorSubject<any>(null);
  private editorderDataSubject= new BehaviorSubject<any>(null);
  orderData$ = this.orderDataSubject.asObservable();
  editedData$=this.editorderDataSubject.asObservable()

  setOrderData(data: any) {
    this.orderDataSubject.next(data);
  }

  setEditedData(data:any){
    this.editorderDataSubject.next(data);

  }

  clearOrderData() {
    this.orderDataSubject.next(null);
  }
}
