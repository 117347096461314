import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogConfig as MatDialogConfig,
  MatLegacyDialog as MatDialog,
} from '@angular/material/legacy-dialog';
import { AirFreightActionsComponent } from 'src/app/air-freight-order/air-freight-actions/air-freight-actions.component';
import { ScheduleDeleteComponent } from 'src/app/air-freight-order/schedule-delete/schedule-delete.component';
import { GridRefreshComponent } from 'src/app/core/grid/grid-refresh/grid-refresh.component';
import { GriddataService } from 'src/app/services/griddata.service';
import { DeleteBtnCellRender } from './review-container.component/delete-btn-cell-render';
import { EditBtnCellRender } from './review-container.component/edit-btn-cell-render';
// import { MatDialog } from '@angular/material/dialog';
import { ReviewChangesComponent } from 'src/app/part-maintenance/review-changes/review-changes.component';
import { DeleteBreaksComponent } from '../delete-breaks/delete-breaks.component';
import { GridBreaksTimeEditorComponent } from './review-container.component/grid-edittimebreaks';
// import * as mm from 'moment-timezone';
import * as moment from 'moment';
import {
  dateFilterComparator,
  dateComparator,
  resetSortingAndFilters,
  warningMessage,
  successMessage,
  checkAccessPipe,
} from 'src/app/constants';
import { ReviewShiftsComponent } from './review-container.component/review-shifts.component';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { ConfirmCancelDailogComponent } from 'src/app/core/confirm-cancel-dailog/confirm-cancel-dailog.component';
import { OspService } from 'src/app/core/master-services/osp.service';
import { NgxSpinnerService } from 'ngx-spinner';

// import { DeleteBreaksComponent } from '../delete-breaks/delete-breaks.component';

@Component({
  selector: 'app-review-changes',
  templateUrl: './review-changes.component.html',
  styleUrls: ['./review-changes.component.scss'],
})
export class ViewBreakComponent implements OnInit {
  rowData: any;
  @ViewChild('osPartsGrid') osPartsGrid;
  columnDefs;
  defaultColDef;
  rowSelection;
  gridOptions = {} as any;
  //gridOptions!: GridOptions;
  paginationPageSize;
  paginationNumberFormatter;
  pagination = true;
  context: any;
  editType;
  suppressClickEdit = true;
  editEnabled: boolean = false;
  today = moment().format('MM/DD/YYYY');
  actionCellRenderer: any;
  gridApi: any;
  headerCheckboxChecked: boolean = false;
  isEditMode: boolean;
  warningMessage: string;
  successMessage: string;
  modalComponent = 'modal-component';
  public frameworkComponents
  itContact: string;
  namcValue = '';
  user = '';
  userRole = '';
  //add breaks
  isExpanded: boolean[] = []; // Declare and initialize here



  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly gridDataService: GriddataService,
    private readonly dialogRef: MatDialogRef<ViewBreakComponent>,
    public dialog: MatDialog,
    private readonly ospService: OspService,
    private readonly spinner: NgxSpinnerService,

    // public dialogRef: MatDialogRef<ReviewChangesComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: any,
    // public dialog: MatDialog,

  ) {
    {
      this.context = {
        componentParent: this,
        refreshHeader: new Subject<void>()
      };
    }
    this.columnDefs = [
      {
        headerName: '',
        field: 'edit',
        colId: "action",
        sortable: false,
        unSortIcon: false,
        width: 55,
        pinned: 'left',
        // cellRenderer: actionCellRenderer,
        editable: false,
        cellRendererFramework: EditBtnCellRender,
        // cellRendererParams: {
        //   clicked: this.actionCellRenderer
        // }
        // cellRendererParams: {
        //   clicked: (field?: any) => {
        //     // this.updateBtn(field)
        //   },
        // },
      },
      {
        headerName: '',
        field: 'delete',
        sortable: false,
        unSortIcon: false,
        pinned: 'left',
        width: 55,
        // cellRendererFramework: CostCenterBtnCellRender,
        cellRendererFramework: DeleteBtnCellRender,
        cellRendererParams: {
          clicked: (field?: any) => {
            this.deleteButton(field)
          },
        },
        cellStyle: { cursor: 'pointer' },
      },
      {
        headerName: 'Shift No',
        field: 'shift_no',
        width: 140,
        editable: false,

      },
      {
        headerName: 'Break From',
        field: 'time_from',
        width: 160,
        editable: true,

        // cellRendererParams: { column: 'breakFrom' },
        // cellRendererFramework: GridBreaksTimeEditorComponent,
        // valueGetter: (params) => {
        //   const data = params.data;

        //   // Logging for debugging
        //   console.log(data.end_time, 'time_from');
        //   console.log(data.breakFrom, 'breakFrom');

        //   // Check if we are in edit mode and whether we need to use start_time or startTime
        //   if (!this.editEnabled) {
        //     // Return startTime or start_time depending on which is available
        //     return data.breakFrom && data.time_from || '';
        //   } else {
        //     // Ensure both start_time and startTime are in the correct format
        //     if (data.time_from) {
        //       // If start_time exists, make sure it's in the correct format (e.g., HH:mm:ss)
        //       if (!moment(data.time_from, 'HH:mm:ss', true).isValid()) {
        //         console.error('Invalid start_time format:', data.time_from);
        //         return ''; // Return empty if invalid format
        //       }
        //     }

        //     if (data.breakFrom) {
        //       // If startTime exists, make sure it's in the correct format (e.g., HH:mm:ss)
        //       if (!moment(data.breakFrom, 'HH:mm:ss', true).isValid()) {
        //         console.error('Invalid startTime format:', data.breakFrom);
        //         return ''; // Return empty if invalid format
        //       }
        //     }

        //     // Use moment.js to safely format the start_time or startTime
        //     const value = moment(data.time_from || data.breakFrom, 'HH:mm:ss', true).isValid()
        //       ? moment(data.time_from || data.breakFrom).format('HH:mm:ss')
        //       : ''; // Fallback to empty string if the format is invalid

        //     return value;
        //   }
        // }
      },
      {
        headerName: 'To Break',
        field: 'time_to',
        width: 170,
        editable: true,
        cellRendererParams: { column: 'timeTo' },
        cellRendererFramework: GridBreaksTimeEditorComponent,
        // valueGetter: (params) => {
        //   if(true) {// if (params.data.rowCheckBox) {
        //     console.log(params.data.toDate,"toDate")
        //     return params.data.toDate;
        //   } else {
        //     console.log("after edit",params.data.to_date)
        //     if (params.data.fromDate) {
        //       params.data.toDate = moment(params.data.toDate).format('MM/DD/YYYY');
        //     }
        //     if (params.data.to_date) {
        //       params.data.to_date = moment(params.data.to_date).format('MM/DD/YYYY');
        //     }
        //     const value = params.data.to_date?.value ?? params.data.toDate;
        //     return value;
        //   }
        // },
        valueGetter: (params) => {
          const data = params.data;

          // Logging for debugging
          console.log(data.end_time, 'time_to');
          console.log(data.endTime, 'timeTo');

          // Check if we are in edit mode and whether we need to use start_time or startTime
          if (!this.editEnabled || !params.data.rowCheckBox) {
            // Return startTime or start_time depending on which is available
            return data.timeTo || data.time_to ;
          } else {
            // Ensure both start_time and startTime are in the correct format
            if (data.end_time) {
              // If start_time exists, make sure it's in the correct format (e.g., HH:mm:ss)
              if (!moment(data.time_to, 'HH:mm:ss', true).isValid()) {
                console.error('Invalid start_time format:', data.time_to);
                return ''; // Return empty if invalid format
              }
            }

            if (data.timeTo) {
              // If startTime exists, make sure it's in the correct format (e.g., HH:mm:ss)
              if (!moment(data.timeTo, 'HH:mm:ss', true).isValid()) {
                console.error('Invalid startTime format:', data.timeTo);
                return ''; // Return empty if invalid format
              }
            }

            // Use moment.js to safely format the start_time or startTime
            const value = moment(data.time_to || data.timeTo, 'HH:mm:ss', true).isValid()
              ? moment(data.time_to || data.timeTo).format('HH:mm:ss')
              : ''; // Fallback to empty string if the format is invalid

            return value;
          }
        }
      },

      
    ];
    this.defaultColDef = {
      editable: false
    };
    this.rowData = null;
    this.rowSelection = 'multiple';
    // this.editType = 'fullRow';
    this.rowData = data
    this.getGridOptions();
  }

  getGridOptions() {
    this.gridOptions = {} as any;
    this.gridOptions = {
      frameworkComponents: {
        EditBtnCellRender: EditBtnCellRender,

      }};
    this.gridOptions.headerHeight = 38;

    this.gridOptions.rowHeight = 28;
    this.gridOptions.floatingFiltersHeight = 0;

    this.gridOptions.rowBuffer = 20;
    this.gridOptions.suppressMovableColumns = true;
    this.gridOptions.enableCellTextSelection = true;
    this.gridOptions.suppressContextMenu = false;
    this.gridOptions.suppressClipboardPaste = true;

    this.gridOptions.defaultColDef = {
      sortable: true,
      suppressMenu: true,
      filter: false,
      floatingFilter: false,

      wrapText: true,
      autoHeight: true,

      suppressPaste: false,

      floatingFilterComponentParams: { suppressFilterButton: true },
      unSortIcon: true,
      icons: {
        sortUnSort: '<img src="../../../assets/images/Rectangle8.svg">',
      },
      filterParams: {
        textCustomComparator(filter, value, filterText) {
          const filterTextLoweCase = filterText.toLowerCase();

          let valueLowerCase = '';
          if (value.params) {
            valueLowerCase = value.value.toString().toLowerCase();
          } else {
            valueLowerCase = value.toString().toLowerCase();
          }
          function contains(target, lookingFor) {
            if (target === null) {
              return false;
            }
            return target.indexOf(lookingFor) >= 0;
          }
          return contains(valueLowerCase, filterTextLoweCase);
        },

        debounceMs: 1000,

        caseSensitive: true,
        suppressAndOrCondition: true,
        inRangeInclusive: true,
      },
    };

    return this.gridOptions;
  }

  enableSave: boolean = false;
  ngOnInit() {
    var errorCount = this.rowData.filter((x) => x.error !== '').length;

    this.enableSave = errorCount === 0;
    this.namcValue = localStorage.getItem('namcvalue');
    this.user = localStorage.getItem('workdayId');
    this.userRole = localStorage.getItem('UserRoles');
    // this.namcName = localStorage.getItem('namcName');
  }
  onCellClicked(params) {
    // Handle click event for action cells
    if ( params.event.target.tagName==="BUTTON") {

      const rowIndex = params.rowIndex;
      const colIndex = params.column.colDef.field;
      this.gridApi.setFocusedCell(rowIndex,colIndex);
      this.gridApi.startEditingCell({rowIndex,colIndex});
    }
  }
        cellRendererFramework: EditBtnCellRender


      

      // params.columnApi.getDisplayedCenterColumns()[0].colId

      // if (action === "delete") {
      //   params.api.applyTransaction({
      //     remove: [params.node.data]
      //   });
      // }

      // if (action === "update") {
      //   params.api.stopEditing(false);
      // }

      // if (action === "cancel") {
      //   params.api.stopEditing(true);
      // }
    
  
  // onCellClicked(params) {
  //   // Handle click event for action cells
  //   if (params.column.colId === "action" && params.event.target.dataset.action) {
  //     let action = params.event.target.dataset.action;

  //     if (action === "edit") {
  //       params.api.startEditingCell({
  //         rowIndex: params.node.rowIndex,
  //         // gets the first columnKey
  //         colKey: params.columnApi.getDisplayedCenterColumns()[0].colId
  //       });
  //     }

  //     // if (action === "delete") {
  //     //   params.api.applyTransaction({
  //     //     remove: [params.node.data]
  //     //   });
  //     // }

  //     if (action === "update") {
  //       params.api.stopEditing(false);
  //     }

  //     if (action === "cancel") {
  //       params.api.stopEditing(true);
  //     }
  //   }
  // }
  onRowEditingStarted(params) {
    params.api.refreshCells({
      columns: ["action"],
      rowNodes: [params.node],
      force: true
    });
  }
  onRowEditingStopped(params) {
    params.api.refreshCells({
      columns: ["action"],
      rowNodes: [params.node],
      force: true
    });
  }
  deleteButton(field: any): void {
    const data1 = {
      offset: 0,
      limit: 1200,
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      shift_id: field?.shift_id,
      break_id: field?.break_id
    };
    const dialogRef = this.dialog.open(DeleteBreaksComponent, {
      data: { field, mode: 'Breaks' },
      height: '200px',
      width: '350px',
    })
    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'cancel') {
        return;
      } else if (res === 'save') {
        this.spinner.show();
        this.ospService.getDeleteShiftBreaks(data1).subscribe({
          error: this.errorCallback,
          next: (data) => {
            if (data.body.breakDeletedCount >= 1) {
              // if (data.body.table1DeletedCount >= 1 || data.body.table2DeletedCount >= 1) {
              // this.onSearch(0);
              this.successMessage = successMessage.deleteRecord;
              this.spinner.hide();
              window.scroll(0, 0);
            } else {
              this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
              this.rowData = [];
              this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
              this.spinner.hide();
            }
          },
        });
      }
    })
   
  }
  hideSpinner() {
    this.spinner.hide();
  }
  errorCallback = (error) => {
    console.error(error);
    this.hideSpinner();

    this.removeMessage();
    this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
  };
  userCheckChanged(checked, rowIndex) {
    // if (
    //   this.warningMessage === 'Please select at least one row to edit.' ||
    //   this.warningMessage === 'No rows are selected.'
    // ) {
    //   this.warningMessage = '';
    // }
    // this.showSpinner();

    const rowNode = this.osPartsGrid.gridOptions.api.getDisplayedRowAtIndex(rowIndex);

    if (checked === true) {
      rowNode.setSelected(true);
      if (this.isEditMode) {
        rowNode.data.checkedEditMode = true;
      }
      rowNode.data.rowCheckBox = true;
    } else {
      rowNode.setSelected(false);
      rowNode.data.checkedEditMode = false;
      rowNode.data.rowCheckBox = false;
    }

    this.gridOptions.getRowStyle = function (params) {
      if (params.node.rowIndex === rowIndex) {
        const color = checked ? '#E4ECF0' : 'white';
        return { background: color };
      }
      return { background: 'white' };
    };

    rowNode.setData(rowNode.data);
    this.toggleHeaderCheckbox(checked);
    // this.hideSpinner();
  }
  toggleHeaderCheckbox(checked) {
    if (!checked) {
      this.headerCheckboxChecked = false;

      this.osPartsGrid.gridOptions.api.refreshHeader();

      return;
    }

    const rowCount = this.osPartsGrid.gridOptions.rowData.length;
    const selectedRowCount = this.osPartsGrid.gridOptions.rowData.filter((x) => x.rowCheckBox === true).length;
    console.log('rowCount', rowCount);
    console.log('selectedRowCount', selectedRowCount);

    if (rowCount === selectedRowCount) {
      this.headerCheckboxChecked = true;

      this.osPartsGrid.gridOptions.api.refreshHeader();
    }
  }
  
  onEditMode() {
    // this.removeMessage();
    let data = [];
    data = this.getModifiedRowData();
    localStorage.setItem('disableDevan', 'true');

    if (data.length > 0) {
      this.isEditMode = !this.isEditMode;
      this.editEnabled = this.isEditMode;

      if (this.isEditMode) {
        this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
          rowNode.data.checkedEditMode = true;
          if (rowNode.data.rowCheckBox) {
          } else {
            rowNode.data.checkedEditMode = false;
          }
          rowNode.setData(rowNode.data);

        });
      } else {
        this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
          if (rowNode.data.rowCheckBox) {
            rowNode.data.checkedEditMode = false;
          }
          rowNode.setData(rowNode.data);
          console.log(rowNode.data,'495')
        });
      }
      setTimeout(() => {
        window.scroll(0, document.body.scrollHeight);
      }, 200);
    } else {
      this.isEditMode = false;
      this.editEnabled = false;
      this.warningMessage = warningMessage.selectRowsToEdit;
      window.scroll(0, 0);
    }
  }
  removeMessage() {
    this.warningMessage = '';
    this.successMessage = '';
  };
  modifiedRowData = [];

  reviewData(): void {
    this.removeMessage();
    this.modifiedRowData = this.getModifiedRowData();
    console.log('modifiedData', this.modifiedRowData);

    if (this.modifiedRowData.length === 0) {
      this.warningMessage = warningMessage.editToReview;

      window.scroll(0, 0);
      return;
    }
    var editedRecords = [];
    this.modifiedRowData = this.modifiedRowData.filter((element) => element.isFromDate || element.isToDate || element.isStartTime || element.isEndTime);
    console.log('editedRecords', this.modifiedRowData);

    if (this.modifiedRowData.length === 0) {
      this.warningMessage = warningMessage.editToReview;

      window.scroll(0, 0);
      return;
    }

    Promise.all(
      this.modifiedRowData.map(async (element, index) => {
        let p = [];
        element.error = '';
        element.container_status_updated = element.shift_id;

    //     // 	If the actual arrival date emptied out,
    //     // once the user confirms the same in popup screen,
    //     // user can save changes – The status of the Renban should be updated
    //     // as “In-Transit”
    //     if (element.actualArrival) {
    //       if (
    //         element.container_status?.toLowerCase() === 'NAMC ARRIVED'.toLowerCase() &&
    //         element.actual_namc_arrival === ''
    //       ) {
    //         p = [];
    //         element.container_status_updated = 'IN-TRANSIT';
    //       }
    //       if (
    //         element.container_status?.toLowerCase() === 'IN-TRANSIT'.toLowerCase() &&
    //         element.estimated_namc_arrival == ''
    //       ) {
    //         p.push('Estimated Arrival date cannot be empty/blank');
    //       }
    //     }

    //     if (element.isestArrival) {
    //       if (
    //         element.container_status?.toLowerCase() === 'NAMC ARRIVED'.toLowerCase() &&
    //         element.estimated_namc_arrival == ''
    //       ) {
    //         p = [];

    //         p.push('Estimated Arrival date cannot be empty/blank');
    //       }
    //       if (
    //         element.container_status?.toLowerCase() === 'IN-TRANSIT'.toLowerCase() &&
    //         element.estimated_namc_arrival == ''
    //       ) {
    //         p = [];

    //         p.push('Estimated Arrival date cannot be empty/blank');
    //       }
    //     }

    //     // EDIT ON INTRANSIT-If the actual arrival date is having data, once the user confirms the data entered,
    //     //user can save changes – The Status of the Renban should be updated
    //     // as “NAMC Arrived”
    //     if (
    //       element.container_status?.toLowerCase() === 'IN-TRANSIT'.toLowerCase() &&
    //       element.actual_namc_arrival != null
    //     ) {
    //       element.container_status_updated = 'NAMC ARRIVED';
    //     }
    //     if (
    //       (element.container_status?.toLowerCase() === 'IN-TRANSIT'.toLowerCase() &&
    //         element.actual_namc_arrival === '') ||
    //       element.actual_namc_arrival === null
    //     ) {
    //       element.container_status_updated = 'IN-TRANSIT';
    //     }

        if (p.length > 0) {
          element.error = p
            .map(function (val, index) {
              return `${(index + 1).toString()}.   ${val}`;
            })
            .join('\n');
        }
      })
    );

    if (this.modifiedRowData.length > 0) {
      this.openReviewDialog(this.modifiedRowData);
    }
  }
  openReviewDialog(editedRecords) {
    var data = {
      modifiedRowData: editedRecords,
    };
    console.log('Modified', data.modifiedRowData);

    if (data.modifiedRowData.length > 0) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.id = this.modalComponent;
      dialogConfig.height = '350px';
      dialogConfig.width = '1000px';

      dialogConfig.data = data;
      const dialogRef = this.dialog.open(ReviewShiftsComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((res) => {
        if (res === 'cancel') {
          return;
        } else if (res === 'save') {
          console.log('inside save', data.modifiedRowData);
          var updatedData = [];
          data.modifiedRowData.forEach((e) => {
            var updatedFields = {
              shift_no: e.shift_no,
              day_of_week: e.day_of_week,
              start_time: e.start_time,
              end_time: e.end_time,
              from_date: e.from_date,
              to_date: e.to_date,
              force_flag: e.force_flag,
              day_offset: e.day_offset,
              shift_id:e.shift_id,
            };
            updatedData.push(updatedFields);
          });
          // this.successMessage = 'Record has been updated successfully';
          window.scroll(0, 0);
          // this.spinner.show();
          // this.editShifts(updatedData);
          this.successMessage = successMessage.recordUpdated;
        }
      });
    } else {
      this.warningMessage = warningMessage.selectRowsToEdit;
      window.scroll(0, 0);
    }
  }
  getModifiedRowData(): any {
    const modifiedRowData = this.rowData.filter((row) => row.rowCheckBox);
    return modifiedRowData;
  }
  cancelEdit(): void {
    this.removeMessage();
    this.openDialog1();
  }
  openDialog1() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = this.modalComponent;
    dialogConfig.height = '250px';
    dialogConfig.width = '470px';
    dialogConfig.data='ospipe'
    // const dialogRef = this.dialog.open(ConfirmCancelDailogComponent, dialogConfig);
    // dialogRef.afterClosed().subscribe((res) => {
    //   if (res === 'cancel') {
    //     this.columnDefs[7].cellStyle = function (params) {
    //       if ('modified' in params.data) {
    //         return { backgroundColor: '#E4ECF0' };
    //       } else {
    //         return { backgroundColor: 'white' };
    //       }
    //     };

    //     // this.onSearch(0);
    //     this.editEnabled = false;
    //     this.isEditMode = false;
    //     this.osPartsGrid.gridOptions.api.refreshHeader();
    //   }
    // });
  }
  //addBreaks
  // this.rowData = [
  //   {
  //     arrivalDate: '',
  //     deadlineDate: '',
  //     noOfPieces: '',
  //     noOfLots: '',
  //     configureLots: '',
  //     ConfigurePieces: '',
  //     etaDate: '',
  //     casenoEditable: '',
  //   },
  // ];
  addRow() {
    // Create a new row object
    const newRow = {
      shift_no: '', // Add other fields as needed with default/empty values
      time_from: '',
      time_to: '',
    };

    // Push the new row into the existing rowData array
    this.rowData = [...this.rowData, newRow];

    // Update the grid with the updated rowData
    this.gridOptions.api.setRowData(this.rowData);
  }

}

