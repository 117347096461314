<span
  style="color: #2688da; cursor: pointer; text-decoration: underline"
  (click)="onClick()"
>

<div *ngIf="isGroupRow">
  {{ params.value }}  <!-- Show air_order_no only for group rows ({{params?.node?.allLeafChildren?.length}})-->
</div>
<div *ngIf="!isGroupRow">
  <!-- For child rows, render nothing or a placeholder -->
  <span style="visibility: hidden;">{{ params.value }}</span> <!-- or you can hide it with CSS -->
</div>

</span>
