import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
// import { ExportStateService } from 'src/app/services/export-state.service'
// import {
//   MatLegacyDialogRef as MatDialogRef,
//   MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
// } from '@angular/material/legacy-dialog';
@Component({
  selector: 'app-delete-breaks',
  templateUrl: './delete-breaks.component.html',
  styleUrls: ['./delete-breaks.component.scss'],
})
export class DeleteBreaksComponent implements OnInit {
  param: any
  fileName: string;
  mesaage: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly dialogRef: MatDialogRef<DeleteBreaksComponent>,
    // private readonly state: ExportStateService,
  ) {}

  ngOnInit(): void {
    console.log(this.data, 'delete')
    console.log(this.data.mode, 'mode')

    if (this.data.mode = 'Shifts') {
      this.fileName = 'Shifts'
    console.log(this.data.mode, 'shifts')

      // this.mesaage = "Are you sure you want to delete this Breaks?"
    } 
    // else if (this.data.mode = 'Budget') {
    //   // this.mesaage = this.data.field.budget
    //   this.fileName = "Budget"

    // } 
    else if (this.data.mode = 'Breaks') {

      console.log(this.fileName, 'breaks')
      // this.mesaage = "Are you sure you want to delete this Shift?"
      this.fileName = 'Breaks'

    } 
    // else if (this.data.mode === 'Fab') {
    //   this.fileName = this.data.field.pkgsupcode
    // } else if (this.data.mode === 'Ringi') {
    //   this.fileName = this.data.field.ringinumber
    // } else if (this.data.mode === 'dock') {
    //   this.fileName = this.data.field.dockcode
    // }
    // this.mesaage = "Are you sure you want to delete this Shift?"

    // if (this.data[0]?.isSchedule) {
    //   this.mesaage = "Are you sure you want to delete this Shift?"

    // }
  }

  closeDialog() {
    this.dialogRef.close()
  }

  dialogData(pNam: string) {
    this.dialogRef.close({ data: pNam })
  }
}