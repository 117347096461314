import { Injectable } from '@angular/core';
import { EnvironmentConfiguration } from 'src/app/environment/services/environment.configuration';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PartsInventoryService {
  getToken = () => localStorage.getItem('Token');

  getHttpCustomOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: localStorage.getItem('Token'),
        'X-Api-Key': this.environment.partsinventoryKey,
      }),
    };
  }

  getApiHeaders = () => {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: this.getToken(),
      'X-Api-Key': this.environment.partsinventoryKey,
    });
  };


  partInventoryApiUrl = '';

  constructor(
    private http: HttpClient,
    private environment: EnvironmentConfiguration
  ) {
    this.partInventoryApiUrl = environment.partsinventoryUrl;
  }

  getpartInventoryGrid(data): Observable<any> {
    return this.http.post<any>(
      `${this.partInventoryApiUrl}/getpartspolicydata`,
      data,
      this.getHttpCustomOptions()
    );
  }
  getpolicyName(data): Observable<any> {
    return this.http.post<any>(
      `${this.partInventoryApiUrl}/getpolicyname`,
      data,
      this.getHttpCustomOptions()
    );
  }
  addpolicy(data): Observable<any> {
    return this.http.put<any>(
      `${this.partInventoryApiUrl}/addpolicy`,
      // 'http://localhost:3000/delete',
      data,
      this.getHttpCustomOptions()
    );
  }

  editpolicy(data): Observable<any> {
    return this.http.put<any>(
      `${this.partInventoryApiUrl}/editpolicy`,
      
      data,
      this.getHttpCustomOptions()
    );
  }

  deletePolicy(data): Observable<any> {
    const httpCustomOptions = {
      body: data,
      headers: this.getApiHeaders(),
    };

    return this.http.delete<any>(
      `${this.partInventoryApiUrl}/deletepolicy`,


      httpCustomOptions
    );
  }




}