import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CustomTooltipComponent } from '../core/custom-tooltip/custom-tooltip.component';
import { HeaderCheckboxComponent } from '../core/grid/header-checkbox/header-checkbox.component';
import { GridRefreshComponent } from '../core/grid/grid-refresh/grid-refresh.component';
import { RowCheckboxComponent } from '../core/grid/row-checkbox/row-checkbox.component';
import { dateFilterComparator, resetSortingAndFilters, validateVanningDates, warningMessage } from '../constants';
import { GriddataService } from '../services/griddata.service';
import { Subscription, forkJoin } from 'rxjs';
import { OSPStateService } from '../services/osp-state.service';
import { LookupService } from '../services/lookup/lookup.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { logErrors } from '../shared/logger';

@Component({
  selector: 'app-module-stocking-devanning',
  templateUrl: './module-stocking-devanning.component.html',
  styleUrls: ['./module-stocking-devanning.component.scss'],

})
export class ModuleStockingDevanningComponent implements OnInit, OnDestroy {
  namcValue = localStorage.getItem('namcvalue');
  userName = localStorage.getItem('userName');

  @ViewChild('osPartsGrid') osPartsGrid;



  user: string;
  userRole: any;
  offset: number = 0;

  count = 0;
  rowData = [];
  columnDefs;
  columnDefsMaster;
  rowSelection;
  context: any;

  gridOptions;
  paginationPageSize;
  paginationNumberFormatter;
  pagination = 'true';


  suppressClickEdit = true;
  frameworkComponents = { customTooltip: CustomTooltipComponent };
  tooltipShowDelay = 0;
  editType;
  headerCheckboxChecked: boolean = false;
  isEditMode = false;




  moduleStockingGrid: any;
  warningMessage: string;
  disableModuleType: boolean;

  //Dropdowns

  selectedDock: any = [];
  stockingDtFrom: any;
  stockingDtTo: any;
  onInstockingDateFrom: any;
  onInstockingDateTo: any;

  //Dropdowns option
  dockList: any = [];
  easternDate: any;
  defaultPageSize: any = 100000;

  showHideList: any = [
    {
      label: 'Dock',
      value: 'dock',
    },
    {
      label: 'Renban',
      value: 'renban',
    },
    {
      label: 'Module',
      value: 'module',
    },
    {
      label: 'Plan LO DT',
      value: 'plan_lo_date',
    },

    {
      label: 'Plan Stock DT/Time',
      value: 'stocking_date',
    },
    {
      label: 'Plan Veh',
      value: 'plan_veh',
    },
    {
      label: 'Countdown to next unload',
      value: 'vehcountdown',
    },
    {
      label: 'Stock',
      value: 'stock',
    },
    {
      label: 'Status',
      value: 'status',
    },
    {
      label: 'Actual Stock DT',
      value: 'actual_stockdt',
    },
    {
      label: 'Actual Veh',
      value: 'actual_veh',
    },
    {
      label: '+/- Veh Stocking',
      value: 'vehStocking',
    },
    {
      label: '+/- Veh Moduling',
      value: 'vehModuling',
    },
    {
      label: 'Shift',
      value: 'shift',
    },

    {
      label: 'User',
      value: 'userid',
    },
  ];

  selectedShowHideList: any = [...this.showHideList];

  adjustSubscription$: Subscription;

  constructor(
    private readonly gridDataService: GriddataService,
    private readonly stateService: OSPStateService,
    private readonly lookupService: LookupService,
    private readonly spinner: NgxSpinnerService,

  ) {
    this.context = {
      componentParent: this,
    };
  }

  ngOnInit(): void {
    debugger
    this.columnDefsMaster = [
      {
        headerName: '',
        field: 'rowCheckBox',
        // suppressSorting: true,
        width: 44,
        headerComponentParams: {
          headerChecked: this.headerCheckboxChecked,
        },
        headerComponentFramework: HeaderCheckboxComponent,
        floatingFilterComponentFramework: GridRefreshComponent,
        floatingFilterComponentParams: { suppressFilterButton: true },

        cellRendererFramework: RowCheckboxComponent,
        floatingFilter: true,


      },
      {
        headerName: 'Dock',
        field: 'dock',
        width: 120,
        floatingFilter: true,
        tooltipField: 'dock',
      },
      {
        headerName: 'Renban',
        field: 'renban',
        sortable: true,
        width: 100,
        floatingFilter: true,
      },
      {
        headerName: 'Module',
        field: 'module',
        sortable: true,
        width: 100,
        floatingFilter: true,
      },
      {
        headerName: 'Plan LO DT',
        field: 'plan_lo_date',
        width: 140,
        headerTooltip: 'LineOff Date(mm/dd/yyyy)',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        floatingFilter: true,
        comparator: this.dateComparator,
      },
      {
        headerName: 'Plan Stock DT/Time',
        field: 'stocking_date',
        width: 140,
        floatingFilter: true,
      },
      {
        headerName: 'Shift',
        field: 'shift',
        sortable: true,
        width: 100,
        floatingFilter: true,
      },
      {
        headerName: 'Plan Veh',
        field: 'plan_veh',
        width: 120,
        floatingFilter: true,
      },
      {
        headerName: 'Countdown to next unload ',
        field: 'vehcountdown',
        width: 120,
        floatingFilter: true,
        headerTooltip: 'Specialist',

      },
      {
        headerName: 'Stock',
        checkboxSelection: true, // This adds checkboxes to each row
        width: 80,
        //suppressSizeToFit: true, // Prevent resizing the checkbox column
      },
      {
        headerName: 'Status',
        field: 'status',
        width: 100,
        floatingFilter: true,
      },
      {
        headerName: 'Actual Stock DT/Time',
        field: 'actual_stockdt',
        width: 140,
        floatingFilter: true,
      },
      {
        headerName: 'Actual Veh',
        field: 'actualVeh',
        sortable: true,
        width: 100,
        floatingFilter: true,
      },
      {
        headerName: '+/- Veh Stocking',
        field: 'vehStocking',
        sortable: true,
        width: 120,
        floatingFilter: true,
      },
      {
        headerName: '+/- Module Stocking',
        field: 'moduleStocking',
        sortable: true,
        width: 120,
        floatingFilter: true,
      },
      {
        headerName: 'User',
        field: 'userid',
        sortable: true,
        width: 100,
        floatingFilter: true,
      },
    ];

    this.gridOptions = {
      onSelectionChanged: this.onSelectionChanged.bind(this)
    };
    this.columnDefs = [...this.columnDefsMaster];
    this.setPageSizeToAll(this.defaultPageSize);
    this.rowSelection = 'multiple';
    this.editType = 'fullRow';
    this.gridOptions = this.gridDataService.getGridOptions();
    this.gridOptions.onGridReady = function (params) {
      this.gridApi = params.api;
    };
    this.adjustSubscription$ = this.stateService
      .getNamc()
      .subscribe((observable) => {
        this.initializeData();
      });

  }

  numberComparator(number1, number2) {
    return number1 - number2;
  }
  dateComparator(date1, date2) {
    function monthToNum(date) {
      if (date === undefined || date === null) {
        return null;
      }

      const newDate: Date = new Date(date);

      const yearNumber = newDate.getFullYear();
      const monthNumber = newDate.getMonth();
      const dayNumber = newDate.getDate();
      const hoursNumber = newDate.getHours();
      const minutesNumber = newDate.getMinutes();

      const result =
        yearNumber * 10000 +
        monthNumber * 100 +
        dayNumber +
        hoursNumber * 60 +
        minutesNumber * 10;
      // 29/08/2004 => 20040829
      return result;
    }

    // To be used inside filter params when using date filter

    const date1Number = monthToNum(date1);
    const date2Number = monthToNum(date2);

    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }

    return date1Number - date2Number;
  }

  removeMessage() {
    this.warningMessage = '';
  }
  onResetDropDown() {
    this.removeMessage();
    this.selectedDock = [];
    this.stockingDtFrom = this.onInstockingDateFrom;
    this.stockingDtTo = this.onInstockingDateTo;

    if (this.osPartsGrid) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();
      this.resetGrid();
    }
    this.setPageSizeToAll(this.defaultPageSize);

    this.rowData = [];
    this.offset = 0;

  }
  toggleHeaderCheckbox(checked) {
    if (!checked) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();
      return;
    }

    const rowCount = this.osPartsGrid.gridOptions.rowData.length;
    const selectedRowCount = this.osPartsGrid.gridOptions.rowData.filter(
      (x) => x.rowCheckBox === true
    ).length;

    if (rowCount === selectedRowCount) {
      this.headerCheckboxChecked = true;
      this.osPartsGrid.gridOptions.api.refreshHeader();
    }
  }

  userCheckChanged(checked, rowIndex) {
    this.spinner.show();
    const rowNode =
      this.osPartsGrid.gridOptions.api.getDisplayedRowAtIndex(rowIndex);
    if (checked === true) {
      rowNode.setSelected(true);
      if (this.isEditMode) {
        rowNode.data.checkedEditMode = true;
      }
      rowNode.data.rowCheckBox = true;
    } else {
      rowNode.setSelected(false);
      rowNode.data.checkedEditMode = false;
      rowNode.data.rowCheckBox = false;
    }

    this.gridOptions.getRowStyle = function (params) {
      if (params.node.rowIndex === rowIndex) {
        const color = checked ? '#E4ECF0' : 'white';
        return { background: color };
      }
      return { background: 'white' };
    };

    rowNode.setData(rowNode.data);
    this.toggleHeaderCheckbox(checked);
    this.spinner.hide();
  }

  headerCheckChanged(event) {
    this.headerCheckboxChecked = event.checked;
    const headerChecked = event.checked;
    const isEditable = this.isEditMode;
    this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
      if (headerChecked) {
        if (isEditable) {
          rowNode.data.checkedEditMode = true;
        }
        rowNode.data.rowCheckBox = true;
      } else {
        rowNode.data.rowCheckBox = false;
        rowNode.data.checkedEditMode = false;
      }
      rowNode.setData(rowNode.data);
    });
  }
  adjs_columns;
  onShowHideChange(ev: any) {
    this.osPartsGrid.gridOptions.columnApi.setColumnsVisible(
      [...this.showHideList.map((e) => e.value)],
      true
    );

    const hideItems = this.showHideList.filter((item) => {
      return !ev.value.map((sItem) => sItem.value).includes(item.value);
    });

    this.osPartsGrid.gridOptions.columnApi.setColumnsVisible(
      [...hideItems.map((e) => e.value)],
      false
    );

    if (ev.onInit) return;

    this.adjs_columns = hideItems;
    if (!hideItems.length) this.adjs_columns = [{}];


  }

  onSearch(offset) {
    //this.refreshView()
    if (this.osPartsGrid) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();
      this.resetGrid();
    }

    this.removeMessage();

    if (offset === 0) {
      this.setPageSizeToAll(this.defaultPageSize);
      this.rowData = [];
      this.osPartsGrid.gridOptions.api.setRowData(this.rowData);

      const { valid, error } = validateVanningDates({
        vanningFrom: this.stockingDtFrom,
        vanningTo: this.stockingDtTo,
      });

      if (!valid) {
        this.warningMessage = error;
        window.scroll(0, 0);
        this.spinner.hide();
        return;
      }

      this.offset = 0;
      //this.spinner.show();
    }

    if (!this.checkIfAnySelected()) {
      this.rowData = [];
      this.warningMessage = warningMessage.selectDock;

      window.scroll(0, 0);
      this.spinner.hide();
      return false;
    }
    let selectedDock;

    if (this.selectedDock.length > 0) {
      if (!this.selectedDock.some((a) => a.dock === 'ALL')) {
        selectedDock = [];
        this.selectedDock.forEach((element) => {
          selectedDock.push(element.dock);
        });
      }

    }

    const data = {
      offset: offset,
      limit: 12000,
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      dock: selectedDock,
      stocking_to: this.stockingDtFrom,
      stockig_from: this.stockingDtTo,
      currenteasternDate: this.easternDate,
    };

    // this.OspService.getAdjustmentGrid(data).subscribe({
    //   error: this.errorCallback,
    //   next: (res) => {
    //     if (res.body && res.body.adjustmentResponse) {
    //       this.rowData.push(...res.body.adjustmentResponse.data);
    //       this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
    //       this.dataCount = parseInt(res.body.adjustmentResponse.rowCount);
    //       const records = this.dataCount - (this.offset + 1) * 12000;
    //       if (records >= 1) {
    //         this.offset++;
    //         this.onSearch(this.offset * 12000);
    //       } else {
    //         this.spinner.hide();
    //       }
    //     } else {
    //       this.rowData = [];
    //       this.spinner.hide();
    //     }

    //     this.setPageSizeToAll();
    //   },
    // });
    return true;
  }

  onPageSizeChanged() {
    if (this.gridOptions?.api) {
      this.gridOptions.api.paginationSetPageSize(
        Number(this.paginationPageSize)
      );
    }
  }

  setPageSizeToAll(pageSize?) {
    this.paginationPageSize = this.rowData.length;

    if (pageSize) {
      this.paginationPageSize = 0;
      this.rowData.length = 0;
    }

    this.onPageSizeChanged();
  }

  exportAsXLSX(): void { };

  ngOnDestroy(): void {
    this.adjustSubscription$.unsubscribe();
  }


  initializeData() {
    this.spinner.show();
    this.rowData = [];
    this.spinner.show();

    this.namcValue = localStorage.getItem('namcvalue');

    if (localStorage.getItem('workdayId')) {
      this.user = localStorage.getItem('workdayId');
    }
    if (localStorage.getItem('UserRoles')) {
      this.userRole = localStorage.getItem('UserRoles');
    }

    this.loadDrop();
    this.onResetDropDown();

    // eastern time api
    this.lookupService.getEasternDate().subscribe({
      error: this.errorCallback,
      next: (res) => {
        this.stockingDtTo = res.body.data;
        this.easternDate = res.body.data;
      },
    });

    this.rowData = [
      { dock: 'AJ', renban: 'G0100', module: 'FD01', plan_lo_date: '11/20/2024', stocking_date: '11/20/2024 o8:55', plan_veh: '148', vehcountdown: '-', status: 'stocked', actual_stockdt: '11/20/2024', actualVeh: '100', vehStocking: '133', moduleStocking: '15', shift: '1', userid: 'Jhon Joe' },
      { dock: 'AJ', renban: 'G0100', module: 'FD01', plan_lo_date: '11/20/2024', stocking_date: '11/20/2024 o8:55', plan_veh: '148', vehcountdown: '-', status: 'stocked', actual_stockdt: '11/20/2024', actualVeh: '100', vehStocking: '133', moduleStocking: '15', shift: '1', userid: 'Jhon Joe' },
      { dock: 'AJ', renban: 'G0100', module: 'FD01', plan_lo_date: '11/20/2024', stocking_date: '11/20/2024 o8:55', plan_veh: '148', vehcountdown: '-', status: 'stocked', actual_stockdt: '11/20/2024', actualVeh: '100', vehStocking: '133', moduleStocking: '15', shift: '1', userid: 'Jhon Joe' },
      { dock: 'AJ', renban: 'G0100', module: 'FD01', plan_lo_date: '11/20/2024', stocking_date: '11/20/2024 o8:55', plan_veh: '148', vehcountdown: '20', status: 'stocked', actual_stockdt: '11/20/2024', actualVeh: '100', vehStocking: '133', moduleStocking: '15', shift: '1', userid: 'Jhon Joe' },
    ]

  }



  checkIfAnySelected() {
    if (
      this.selectedDock.length > 0
    ) {
      return true;
    }
    return false;
  }

  resetGrid() {
    if (this.osPartsGrid) {
      resetSortingAndFilters(this.osPartsGrid);
      return;
    }
  }


  errorCallback = (error) => {
    logErrors(error);
    this.spinner.hide();
    this.warningMessage = 'Something went wrong! Please contact support team.';
    this.rowData = [];
    this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
  };

  // Service call
  loadDrop() {
    this.spinner.show();

    const data = {
      offset: this.offset,
      limit: 12000,
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      stocking_to: this.stockingDtTo,
      stocking_from: this.stockingDtFrom,
      currenteasternDate: this.easternDate,

    };

    const lookupSubscriptions = [
      this.lookupService.getDock(),
      this.lookupService.getUserShowHideColumns(),
    ];

    forkJoin(lookupSubscriptions).subscribe({
      next: (res) => {
        this.dockList = [];
        if (res[0].body.data) {
          this.dockList.push({ dock: 'ALL' });
        }
        this.dockList.push(...res[0].body.data);
        const { adjs_columns } = res[1].body.showHideColumnsResponse;
        try {
          const hideItems = JSON.parse(adjs_columns);

          if (typeof hideItems === 'object' && hideItems.length > 0) {
            this.selectedShowHideList = this.showHideList.filter(
              (sItem) =>
                !hideItems.map((item) => item.value).includes(sItem.value)
            );


            this.onShowHideChange({
              value: this.selectedShowHideList,
              onInit: true,
            });
          }
        } catch (error) { }

        // this.onInitVanningTo = this.vanningTo;
        this.spinner.hide();
      },
      error: this.errorCallback,
    });

    //

    // this.lookupService.getDock().subscribe({
    //   next: (res) => {
    //     this.dockList = [];
    //     if (res.body.data) {
    //       this.dockList.push({ dock: 'ALL' });
    //     }
    //     this.dockList.push(...res.body.data);
    //     this.spinner.hide();

    //   },

    //   error: this.errorCallback,
    // });
  }
  saveShowHide() {
    this.spinner.show();

    let adjs_columns = this.adjs_columns;
    if (adjs_columns) {
      this.lookupService
        .updateUserShowHideColumns({ adjs_columns })
        .subscribe({
          error: this.errorCallback,
          next: (res) => {
            console.log(res);
            this.spinner.hide();

          },
        });
    }
    else {
      this.spinner.hide()
    }
  }


  dockChange(): void {
    if (this.selectedDock.length >= 1) {
      if (this.selectedDock[this.selectedDock.length - 1].dock === 'ALL') {
        this.selectedDock = [];
        this.selectedDock.push(this.dockList[0]);
      } else {
        let indexAll = this.selectedDock.findIndex(
          (data) => data.dock === 'ALL'
        );

        if (indexAll > -1) {
          this.selectedDock.splice(indexAll, 1);
        }
      }
    }

  }

  onSelectionChanged() {
    const selectedRows = this.gridOptions.api.getSelectedRows();
    console.log('Selected rows:', selectedRows);

    // You can also get all rows' data
    const allRows = [];
    this.gridOptions.api.forEachNode((node) => {
      allRows.push(node.data);
    });
    console.log('All rows data:', allRows);
  }

  onRowChanged(event) {
    const changedRows = event.api.getSelectedRows();
    console.log("Row Changed:>>>>" + changedRows);
  }
}
