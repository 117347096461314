import { Component } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-actual-arrival-editable',
  template: `
    <input #input [(ngModel)]="value" type="date" class="grid-cell-edit-layout " (change)="textUpdated()" />
  `,
  styles: [
    `
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      .grid-cell-edit-layout {
        width: 123px;
        height: 26px;
        border: 1px solid #d1d1d1;
      }
      .invalid-text {
        border: 2px solid red !important;
      }
    `,
  ],
})
export class EditVanCenterEtaEditableComponent {
  public params: any;
  showInput = true;
  value: any;
  isEmpty = true;

  constructor() {}

 
  agInit(params: any): void {
    console.log(params, 'params...');

    params.data.etaDate = this;
    
    if (params.column === 'eta_date') {
      console.log('column....');
      this.value = params.data.eta_date;
      this.value = moment(this.value).format('YYYY-MM-DD');
      console.log(this.value, 'value......');
    }

    this.params = params;
    console.log(this.params, 'params......');
  }

 



  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  public textUpdated() {
    console.log(this.params.data, 'ui');
    if (this.params.column === 'eta_date') {
      if (this.value !== this.params.data.ets_date) {
        this.params.data.eta_date = this.value;
        this.params.data.isUpdated= true
      }
    } else {
      this.params.data.finalquantity = this.value;
    }
  }

  onKeyPress(event) {
    if (event.target.value.length <= 4) {
      return true;
    } else {
      return false;
    }
  }
}
